import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutUs from "../Pages/AboutUs";
import Cart from "../Pages/Cart";
import ContactUs from "../Pages/ContactUs";
import Category from "../Pages/Category";
import Checkout from "../Pages/Checkout";
import Home from "../Pages/Home";
import CreditCardPayment from "../CustomComponents/CreditCardPayment";
import PayMe from "../CustomComponents/PayMe";
import AliPay from "../CustomComponents/AliPay";
import PayPal from "../CustomComponents/PayPal";
import ScheduleDelivery from "../Pages/Schedule";
import ProductListPage from "../Pages/ProductListPage";
import UserProfile from "../Pages/UserProfile";
import PasswordResetPage from "../Pages/PasswordResetPage";
import EmailConfirmation from "../Pages/EmailConfirmation";
import api from "../../services/api";
import { CategoryContext } from "../Context/Category";
import GoogleAuthenticationPage from "../Pages/GoogleAuthenticationPage";
import FbAuthenticationPage from "../Pages/FbAuthenticationPage";
import SubCategoryPage from "../Pages/SubCategoryPage";
import "../../scss/include/navBar.scss";
import BrandProductListPage from "../Pages/BrandProductListPage";
import PromotionProductListPage from "../Pages/PromotionProductListPage";
import Wishlist from "../Pages/Wishlist";
import SearchPage from "../Pages/SearchPage";
import DollarRewardSystem from "../Pages/DollarRewardSystem";
import Service from "../Pages/Service";
import Shipping from "../Pages/Shipping";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import AdvertiseWithUs from "../Pages/AdvertiseWithUs";
import PaymentMethod from "../Pages/PaymentMethod";
import BlogPage from "../Pages/BlogPage";
import BlogDetails from "../Pages/BlogDetails";
import StoreLocator from "../Pages/StoreLocator";
import ErrorPage404 from "../Pages/ErrorPage404";
import Error500Page from "../Pages/Error500Page";
import SocialResponsibility from "../Pages/SocialResponsibility";
import HelpPage from "../Pages/HelpPage";
import { NavLink } from "react-router-dom";
import InStoreAdvertisment from "../Pages/InStoreAdvertisment";
import OutdoorAdvertisment from "../Pages/OutdoorAdvertisment";
import RadioAdvertisment from "../Pages/RadioAdvertisment";
import SpecialEventsAdvertisment from "../Pages/SpecialEventsAdvertisment";
import logo from "../../assets/img/logo.png";
import useTransPara from "../hooks/useTransPara";
import MyOrderDetailsById from "../Pages/MyOrderDetailsById";
import ScrollToTop from "../CustomComponents/ScrollToTop";
import useTrans from "../hooks/useTrans";
import ReturnRefundPolicy from "../Pages/ReturnRefundPolicy";
import ProductPage from "../Pages/ProductPage";
import BrandHomePageComponent from "../CustomComponents/BrandHomePageComponent";
import RealTasteOfIndia from "../Pages/RealTasteOfIndia";
import RealCart from "../Pages/RealCart";
import RealCheckout from "../Pages/RealCheckout";
import BhavikaRestaurant from "../Pages/BhavikaRestaurant";

const Navbar = () => {
  const transName = useTransPara("name");

  const trans = useTrans();

  const [mobileMenuClassState, setMobileMenuClassState] = useState("");
  const [overLayClassState] = useState("openOverlay");
  const [subCatClassState, setSubCatClassState] = useState("mainCategory");
  const { category, setCategory } = useContext(CategoryContext);
  const [showDropDown, setShowDropDown] = useState("");
  const [subNav, setSubNav] = useState([]);
  // const showCategoryDropdown = () => {
  //   const catDropdown = document.getElementById("category-dropdown");
  //   catDropdown.classList.toggle("show");
  // };
  // const hideCategoryDropdown = () => {
  //   const catDropdown = document.getElementById("category-dropdown");
  //   catDropdown.classList.toggle("hide");
  // };
  useEffect(() => {
    async function getCategory() {
      const categoryRes = await api.get("/api/v1/category");
      if (categoryRes && categoryRes.data) {
        setCategory(categoryRes.data);
      }
    }
    getCategory();
  }, [setCategory]);

  useEffect(() => {
    const subCats = [];
    category.forEach((cat) => {
      cat.subcategory.forEach((subcat) => {
        if (subcat.navbar) {
          subCats.push({
            _id: subcat._id,
            cat_slug: cat.slug,
            subcat_slug: subcat.slug,
            name: subcat.name,
            name_hk: subcat.name_hk,
          });
        }
      });
    });
    setSubNav(subCats.slice(0, 5));
  }, [category]);

  const hamburgerBtnClicked = () => {
    // setMobileMenuClassState('show')
    mobileMenuClassState === ""
      ? setMobileMenuClassState("show")
      : setMobileMenuClassState("");

    showDropDown === "" ? setShowDropDown("show") : setShowDropDown("");
  };
  const pageLinkClicked = () => {
    setMobileMenuClassState("");
    setShowDropDown("");
  };
  const overLayClicked = () => {
    setMobileMenuClassState("");
    showDropDown === "show" ? setShowDropDown("") : setShowDropDown("");
  };

  const arrowClickHandler = (index) => {
    // setSubCatClassState('mainCategoryMobileSubCatDisplay');
    subCatClassState === "mainCategory"
      ? setSubCatClassState("mainCategoryMobileSubCatDisplay")
      : setSubCatClassState("mainCategory");

    subCatClassState === "mainCategory"
      ? (document.getElementById(index).style.display = "block")
      : (document.getElementById(index).style.display = "");

    subCatClassState === "mainCategory"
      ? (document.getElementById(index).style.display = "block")
      : (document.getElementById(index).style.display = "");
  };

  const returnToNavMenu = (index) => {
    document.getElementById(index).style.display = "";
  };

  return (
    <BrowserRouter>
      <div id="top" name="top"></div>
      <Header />
      <BrandHomePageComponent />
      <nav className="navbar navbar-expand-lg bg-white">
        <button
          className="navbar-toggler"
          type="button"
          // data-toggle="collapse"
          // data-target="#navbarTogglerDemo03"
          // aria-controls="navbarTogglerDemo03"
          // aria-expanded="false"
          // aria-label="Toggle navigation"
          onClick={hamburgerBtnClicked}
          style={{ zIndex: "1024" }}
        >
          <i className="fa fa-bars" />
        </button>

        <div
          className={`collapse navbar-collapse ${mobileMenuClassState} `}
          id={"navbarTogglerDemo03"}
        >
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <div className="d-flex justify-content-end align-items-center">
              {mobileMenuClassState === "show" ? (
                <>
                  <img
                    src={logo}
                    className={"img-fluid mr-auto"}
                    style={{ width: "140px" }}
                    alt="logo"
                  />
                  <i
                    className="fa fa-times-circle fa-2x"
                    onClick={hamburgerBtnClicked}
                  ></i>
                </>
              ) : (
                ""
              )}
            </div>
            <li
              className="nav-item dropdown"
              id={"category-dropdown-item"}
              // onPointerEnter={() => showCategoryDropdown()}
              // onPointerLeave={() => hideCategoryDropdown()}
            >
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                data-toggle="dropdown"
              >
                <i className="fas fa-list" />
                &nbsp;&nbsp;{trans.categories}
              </a>
              <ul
                className={`dropdown-menu categoryListMenuMainDiv ${showDropDown}`}
                id="category-dropdown"
              >
                {category.map((cat, index) => {
                  if (cat.subcategory && cat.subcategory.length > 0) {
                    return (
                      <li
                        className={`has-submenu  ${subCatClassState} `}
                        key={index}
                      >
                        <div className="d-flex justify-content-center align-items-center categoryAndIconMainDiv">
                          <a
                            className="dropdown-item  categoryNameHeader"
                            href={`/category/${cat.slug}`}
                          >
                            <span
                              className="categoryNameHeaderName"
                              style={{ fontSize: "18px" }}
                            >
                              {transName(cat)}
                            </span>
                          </a>
                          {/* subcat */}
                          <i
                            className={`fa ${
                              mobileMenuClassState === ""
                                ? `fa-angle-right`
                                : `fa-plus-square`
                            } mainCategoryArrow mr-3`}
                            onClick={
                              mobileMenuClassState === ""
                                ? null
                                : () => arrowClickHandler(index)
                            }
                          >
                            <div className="subCategoryItems" id={`${index}`}>
                              <ul className="list-unstyled">
                                <div className="subMenuMainDivHeader">
                                  {mobileMenuClassState === "show" ? (
                                    <div
                                      className="w-100 mb-2 p-2 d-flex align-items-center"
                                      style={{
                                        backgroundColor: "#D39E00",
                                        color: "white",
                                      }}
                                      onClick={() => returnToNavMenu(index)}
                                    >
                                      <i className="fas fa-arrow-circle-left fa-2x ml-2"></i>
                                      <span
                                        style={{
                                          textTransform: "uppercase",
                                          fontSize: "25px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {transName(cat)}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {cat.subcategory.map((subcat, index) => {
                                    return (
                                      <li key={index}>
                                        <a
                                          className="dropdown-item  subCatList text-white m-1 w-auto"
                                          href={`/category/${cat.slug}/${subcat.slug}`}
                                          style={{ backgroundColor: "#21920F" }}
                                        >
                                          <span
                                            className="subCatListName"
                                            style={{ fontSize: "18px" }}
                                          >
                                            {transName(subcat)}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </div>
                              </ul>
                            </div>
                          </i>
                          {/* subcat ends */}
                        </div>
                      </li>
                    );
                  }
                  return (
                    <li key={index}>
                      <a
                        className="dropdown-item categoryNameHeader"
                        href={`/category/${cat.slug}`}
                      >
                        <span
                          className="categoryNameHeaderName"
                          style={{ fontSize: "18px" }}
                        >
                          {transName(cat)}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
            {/* <li className="nav-item left-nav">
              <a className="nav-link" href="/#">
                New Buyer Rewards
              </a>
            </li>
            <li className="nav-item left-nav">
              <a className="nav-link" href="/#">
                Gifting & Rewards
              </a>
            </li> */}
            {category
              .filter((item) => item.navbar)
              .slice(0, 5)
              .map((item) => (
                <li className="nav-item" key={item._id}>
                  <NavLink className="nav-link" to={`/category/${item.slug}`}>
                    {transName(item)}
                  </NavLink>
                </li>
              ))}
            {subNav.map((item) => (
              <li className="nav-item" key={item._id}>
                <NavLink
                  className="nav-link"
                  to={`/category/${item.cat_slug}/${item.subcat_slug}`}
                >
                  {transName(item)}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="navbar-nav my-2 my-lg-0 right-nav">
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                data-toggle="dropdown"
              >
                Services
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 1{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 2{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 3{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 6{" "}
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                data-toggle="dropdown"
              >
                Sell On Bhavika
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 1{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 2{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 3{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 6{" "}
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/service"
                onClick={pageLinkClicked}
              >
                Services
              </NavLink>
            </li> */}
            <li className="nav-item">
              {/* <a className="nav-link" href="/service"> */}

              {/* onClick handler is used to hide the drawer when this link is clicked */}
              <NavLink
                className="nav-link"
                to="/real-taste-of-india"
                onClick={pageLinkClicked}
              >
                Real Taste Of India
              </NavLink>
              {/* </a> */}
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="/service"> */}

              {/* onClick handler is used to hide the drawer when this link is clicked */}
              <NavLink
                className="nav-link"
                to="/blog"
                onClick={pageLinkClicked}
              >
                {trans.blogs}
              </NavLink>
              {/* </a> */}
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="/contact"> */}
              <NavLink
                className="nav-link"
                to="/contact"
                onClick={pageLinkClicked}
              >
                {trans.sellOnBhavika}
              </NavLink>
              {/* </a> */}
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="/social_responsibility"> */}
              <NavLink
                className="nav-link"
                to="/social_responsibility"
                onClick={pageLinkClicked}
              >
                <i className="fa fa-heart text-danger" />{" "}
                {trans.socialResponsibility}
              </NavLink>
              {/* </a> */}
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="/help"> */}
              <NavLink
                className="nav-link"
                to="/help"
                onClick={pageLinkClicked}
              >
                {trans.help}
              </NavLink>
              {/* </a> */}
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                data-toggle="dropdown"
              >
                Help
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 1{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 2{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 3{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    {" "}
                    Dropdown item 6{" "}
                  </a>
                </li>
              </ul>
            </li> */}
            <li className="nav-item app-link">
              <a className="nav-link" href="/#bhavika_app">
                {trans.getTheApp}
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {mobileMenuClassState === "show" ? (
        <div className={`${overLayClassState}`} onClick={overLayClicked}></div>
      ) : (
        ""
      )}
      <ScrollToTop />
      <Switch>
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/carts" component={RealCart} />
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/category/:slug" component={Category} />
        <Route exact path="/products/:slug" component={ProductPage} />
        <Route exact path="/search" component={SearchPage} />
        <Route
          exact
          path="/category/:category/:subcategory"
          component={SubCategoryPage}
        />
        <Route exact path="/brand/:slug" component={BrandProductListPage} />
        <Route
          exact
          path="/promotion/:slug"
          component={PromotionProductListPage}
        />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/checkouts" component={RealCheckout} />
        <Route exact path="/schedule-delivery" component={ScheduleDelivery} />
        <Route
          exact
          path="/checkout/creditcard"
          component={CreditCardPayment}
        />
        <Route exact path="/checkout/payme" component={PayMe} />
        <Route exact path="/checkout/alipay" component={AliPay} />
        <Route exact path="/checkout/paypal" component={PayPal} />
        <Route exact path="/product" component={ProductListPage} />
        <Route exact path="/profile" component={UserProfile} />
        <Route exact path="/myorders/:id" component={MyOrderDetailsById} />
        {/* <Route exact path="/password_reset" component={PasswordResetPage} /> */}
        <Route exact path="/change-password" component={PasswordResetPage} />
        <Route exact path="/confirmation" component={EmailConfirmation} />
        <Route
          exact
          path="/authenticate/google"
          component={GoogleAuthenticationPage}
        />
        <Route
          exact
          path="/authenticate/facebook"
          component={FbAuthenticationPage}
        />
        <Route exact path="/wishlist" component={Wishlist} />
        <Route
          exact
          path="/dollar-reward-system"
          component={DollarRewardSystem}
        />
        <Route exact path="/service" component={Service} />
        <Route exact path="/shipping" component={Shipping} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/return-refund-policy"
          component={ReturnRefundPolicy}
        />
        <Route exact path="/advertise-with-us" component={AdvertiseWithUs} />
        <Route
          exact
          path="/advertise-with-us/Store"
          component={InStoreAdvertisment}
        />
        <Route
          exact
          path="/advertise-with-us/Outdoor"
          component={OutdoorAdvertisment}
        />
        <Route
          exact
          path="/advertise-with-us/Storeradio"
          component={RadioAdvertisment}
        />
        <Route
          exact
          path="/advertise-with-us/SpecialEvents"
          component={SpecialEventsAdvertisment}
        />
        <Route exact path="/payment-methods" component={PaymentMethod} />
        <Route exact path="/blog" component={BlogPage} />
        <Route exact path="/blog/:slug" component={BlogDetails} />
        <Route exact path="/location" component={StoreLocator} />
        {/* <Route exact path="/error404" component={ErrorPage404} /> 
        <Route component={Home} />
        */}
        <Route exact path="/" component={Home} />
        <Route exact path="/error500" component={Error500Page} />
        <Route
          exact
          path="/social_responsibility"
          component={SocialResponsibility}
        />
        <Route exact path="/help" component={HelpPage} />
        <Route exact path="/real-taste-of-india" component={RealTasteOfIndia} />
        <Route exact path="/bhavika-restaurant" component={BhavikaRestaurant} />
        <Route exact path="/404" component={ErrorPage404} />
        <Route component={ErrorPage404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Navbar;
