import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_BASE_URL } from "../../config";
import { consoleLog } from "../../utils/helper";
// import api from '../../services/api';

const qs = require("qs");

const FbAuthenticationPage = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let FBCode = params.get("code");
    // Console log the code get from url
    consoleLog(FBCode);

    let query = qs.stringify({ code: FBCode });
    if (FBCode) {
      setErrorMessage(null);
      Axios.get(`${API_BASE_URL}/api/v1/auth/facebook?${query}`)
        .then((res) => {
          // consoleLog(res)
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("userData", JSON.stringify(res.data.user));
          window.location.href = "/";
        })
        .catch((err) => {
          if (err && err.message) {
            setErrorMessage(err.message);
          } else {
            setErrorMessage("Error logging in. Please try again");
          }
          //   consoleLog(err);
        });
    } else {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center flex-column align-items-center">
            <span
              className="font-weight-bold mb-3"
              style={{ fontSize: "25px" }}
            >
              Login with Facebook
            </span>
            {errorMessage ? (
              <div className=" d-flex flex-column mt-3 mb-3">
                {errorMessage}
              </div>
            ) : (
              <>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className=" d-flex flex-column mt-3 mb-3">
                  <span>
                    Thank you for registrating in Bhavika,
                    <br />
                    <br />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default FbAuthenticationPage;
