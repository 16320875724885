import React from 'react';
import "./../../scss/include/_schedule.scss";
const ScheduleDelivery = () => {
    return (
        <div className="container-fluid schedule-delivery">
            <div className="row">
                <div className="col-md-5 col-sm-12 col-12 col-lg-4 col-xl-5 d-flex imgDivOfStore">
                    <img src={require('../../assets/img/vector1.png')} alt="test" className={"img-fluid"}/>
                </div>
                <div className="col-md-7 col-sm-12 col-12 col-lg-8 col-xl-7 text-section">
                    <h5 className="font-weight-bold title">
                        Choose Your Desired Items!
                    </h5>
                    <p className="desc">
                        Choose your preferred items out of thousands of products.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-7 col-sm-12 col-12 col-lg-8 col-xl-7 text-section">
                    <h5 className="font-weight-bold title text-on-right">
                        Add Products to Cart & Checkout!
                    </h5>
                    <p className="desc text-on-right">
                        Add the items to the cart and checkout using your preferred payment method.
                    </p>
                </div>
                <div className="col-md-5 col-sm-12 col-12 col-lg-4 col-xl-5">
                    <img src={require('../../assets/img/vector2.png')} alt="test" className={"img-fluid"}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 col-sm-12 col-12 col-lg-4 col-xl-5 d-flex imgDivOfStore">
                    <img src={require('../../assets/img/vector3.png')} alt="test" className={"img-fluid"}/>
                </div>
                <div className="col-md-7 col-sm-12 col-12 col-lg-8 col-xl-7 text-section">
                    <h5 className="font-weight-bold title">
                        Choose Your Delivery Date & Location!
                    </h5>
                    <p className="desc">
                        Choose your preferred delivery date and location.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-7 col-sm-12 col-12 col-lg-8 col-xl-7 text-section">
                    <h5 className="font-weight-bold title text-on-right">
                        Get It Delivered!
                    </h5>
                    <p className="desc text-on-right">
                        Get your desired items delivered your location on preferred date.
                    </p>
                </div>
                <div className="col-md-5 col-sm-12 col-12 col-lg-4 col-xl-5">
                    <img src={require('../../assets/img/vector4.png')} alt="test" className={"img-fluid"}/>
                </div>
            </div>
            <div className="container get-started text-center">
                <h4>Get Started with Delivery Schedule</h4>
                <a href="/" className="btn btn-lg">Get Started <i className="fa fa-chevron-circle-right"></i></a>
            </div>
        </div>
    );
}
export default ScheduleDelivery;