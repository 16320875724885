import React from "react";
import { Link } from "react-router-dom";
import "../../scss/include/privacyPolicy.scss";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";

const PrivacyPolicy = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Privacy Policy"
        toHref="privacy-policy"
      />
      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">Privacy Policy</span>
            <span className="privacyDescSpan">
              BhavikaOnline is committed to respecting the privacy of our
              visitors and customers. We take this matter very seriously and
              have instituted rigorous policies and procedures to enforce your
              rights as stated herein. By registering at BhavikaOnline, you are
              agreeing to the policies detailed below.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              What if I communicate with BhavikaOnline electronically?
            </span>
            <span className="privacyDescSpan">
              When you send an e-mail to BhavikaOnline or visit the web site,
              you are communicating with us electronically. When you send an
              email to BhavikaOnline, you hereby consent to receive an e-mail in
              response. Sending an e-mail to BhavikaOnline does not put you on
              our e-mail list unless your e-mail explicitly requests that you be
              put on the e-mail list. You hereby agree that all agreements,
              notices, disclosures and other electronic communications satisfy
              any legal requirement that such communications be in writing. All
              communications with BhavikaOnline become the property of
              BhavikaOnline.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              What information does a BhavikaOnline collect?
            </span>
            <span className="privacyDescSpan">
              In order to serve you better, BhavikaOnline collects and stores
              your personal information when you create a BhavikaOnline account
              or join a BhavikaOnline e-mail list. Joining an e-mail list
              requires your e-mail address only. Creating an account requires
              your first and last name, e-mail address, and a password.
              Purchases require the above plus your billing address, shipping
              address, and credit card information. Our system also records the
              IP address you use to connect to our site. We may use this data
              internally only for two reasons: 1) to personalize your experience
              on BhavikaOnline and the marketing materials you receive from
              BhavikaOnline or its partners, and 2) to prevent fraud on your
              account. We do not share this information with anyone except as
              may be required by law. We do use navigational information to
              understand how our visitors use BhavikaOnline. We cannot and do
              not keep track of your browsing activity except as noted herein.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              What does a BhavikaOnline do with this personal information?
            </span>
            <span className="privacyDescSpan">
              Your information is used to process your orders, personalize your
              shopping experience on BhavikaOnline, and to comply with any legal
              requirements. By storing your personal information on a secure
              server, BhavikaOnline is able to shorten your checkout process and
              allow you to check your order status online. At BhavikaOnline, we
              also review what kinds of products appeal the most to our
              customers as a group. This statistical information helps us
              improve our offering in the same way that other companies change
              their catalog based on what sells best. If you have elected to
              receive e-mail offers, we would use this information about the
              kinds of products you buy from BhavikaOnline to make other
              marketing offers to you.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              Does BhavikaOnline share any information it collects with outside
              parties?
            </span>
            <span className="privacyDescSpan">
              BhavikaOnline uses your personal information solely for your
              shopping convenience at BhavikaOnline. We use this information to
              prevent fraudulent purchases, and our shipping company requires
              your name and address to deliver your order. Beyond that,
              BhavikaOnline does not share any information it collects with
              outside parties except as may be required by law.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              How does BhavikaOnline safeguard my personal information?
            </span>
            <span className="privacyDescSpan">
              BhavikaOnline has implemented a variety of security measures to
              maintain the safety of your personal information. Your personal
              information is contained behind secured networks and is only
              accessible by a limited number of employees who have special
              access rights. When you place orders or access your personal
              information, we offer the use of a secure server. All sensitive /
              credit information supplied by you is transmitted via Secure
              Socket Layer (SSL) technology and securely stored in
              BhavikaOnline's database. Virtually all retailers - online and
              traditional - use this technology.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              Does a BhavikaOnline provide a way to update or remove personal
              information?
            </span>
            <span className="privacyDescSpan">
              BhavikaOnline provides a "my account" feature on our website
              BhavikaOnline which allows you to modify and/or remove most of
              your personal information. This page can be accessed by clicking
              the "my account" link at the bottom of every page on the site. If you want to completely delete/remove personal information, please contact us at  <a href="mailto:info@bhavika.online" style={{textDecoration:'none'}}>info@bhavika.online</a> and we will delete all the information within a month.  This includes all the social login(Facebook, Google, etc) details as well.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              How does BhavikaOnline use my e-mail address?
            </span>
            <span className="privacyDescSpan">
              Your e-mail address is used to create and identify your account on
              BhavikaOnline. Your e-mail address is also used by BhavikaOnline
              to correspond with you about any orders that you may place. If you
              have joined our e-mail lists, we may also use your e-mail address
              to notify you about special offers. If you have not joined our
              e-mail lists we will use your e-mail address solely for
              notifications regarding your purchases. E-mail list subscribers
              can elect to be removed at any time.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              Does BhavikaOnline use "cookies"?
            </span>
            <span className="privacyDescSpan">
              A cookie is a small piece of information sent by a web site that
              is saved on your hard disk by your computer's browser. The cookie
              holds information a site may need to personalize your experience
              and to gather web site statistical data, such as which pages are
              visited, the internet provider's domain name and country that our
              visitors come from (e.g. "aol.com" or "msn.com") and the addresses
              of the sites visited immediately before coming to and immediately
              after leaving{" "}
              <a href="https://www.bhavika.online">BhavikaOnline</a>. However,
              none of this information is associated with you as an individual.
              It is measured anonymously and only in the aggregate. The
              information in the cookies lets us trace your "click stream"
              activity (i.e., the paths take by visitors to our site as they
              move from page to page). Cookies do not capture your individual
              email address or any private information about you. BhavikaOnline
              may use third-party advertising companies to serve ads on our web
              site as well as third party companies to provide tracking and
              analysis of information and data collected from visitors to our
              web site. These companies may employ cookies and action tags (also
              known as single pixel gifs or web beacons) to measure advertising
              effectiveness. Any information that these third parties collect
              via cookies and action tags is completely anonymous and
              BhavikaOnline reserves the right to provide that information and
              data to these third parties. To browse without cookies, you can
              configure your browser to reject all cookies, or to notify you
              when a cookie is set. Each browser is different so check the
              "Help" menu of your browser to learn how to change your cookie
              preferences. Even without accepting a cookie, you can still access
              most of the features on BhavikaOnline.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="privacyHeadingSpan mb-2">
              How can you remove your name from our direct mail or email list?
            </span>
            <span className="privacyDescSpan">
              To have your email address removed from our list of addresses that
              receive information about new products, service, sales and
              promotions, simply click on the "Unsubscribe" link that appears at
              the bottom of any email and follow the simple instructions.
              Alternately, if you have created an account with BhavikaOnline,
              you can also remove your email address from our list of addresses
              that receive information about new products and promotions by
              clicking on the "my account" button that appears on most every
              page of our Web site, logging into your account, clicking on the
              "edit preferences" button, and making the appropriate change.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <span className="privacyNoteSpan mr-2">PLEASE NOTE:</span>
            <span className="privacyDescSpan">
              It can take up to 3 business days to process this request so you
              may get another email from us. Thank you in advance for your
              understanding.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column">
            <span className="privacyNoteSpan mt-2">Your consent</span>
            <span className="privacyDescSpan">
              you acknowledge that this privacy policy is part of the terms of
              service and you agree that using this site signifies your consent
              to BhavikaOnline privacy policy.BhavikaOnline reserves the right
              to change the terms of service, including this privacy policy, at
              any time, without advanced notice.BhavikaOnline does not
              anticipate any changes to this policy, but if they occur,
              BhavikaOnline will post the changes prior to their taking effect.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column">
            <span className="privacyNoteSpan mt-2">Tell us what you think</span>
            <span className="privacyDescSpan">
              If you have any comments or questions about BhavikaOnline:
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column">
            <span className="privacyNoteSpan mt-2">Contact Us:</span>
            <span className="privacyDescSpan">
              BhavikaOnline is keen to provide you with the best possible
              standards of online service. To help us, we would appreciate your
              comments and Suggestions on how we can improve your online
              experience with BhavikaOnline. You can put your query{" "}
              <Link to="/contact">here</Link>.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column">
            <span className="privacyNoteSpan mt-2">
              BhavikaOnline Customer Service:
            </span>
            <span className="privacyDescSpan">
              For questions or comments regarding BhavikaOnline products,
              services or web site content.
            </span>
            <span className="privacyDescSpan">
              Email:{" "}
              <a
                href="mailto:info@bhavika.online"
                style={{ textDecoration: "underline" }}
              >
                info@bhavika.online
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column">
            <span className="privacyNoteSpan mt-2">By Post :</span>
            <span className="privacyDescSpan">Shop G03, G/F,</span>
            <span className="privacyDescSpan">T-Bay, 9 Yi Tung Road,</span>
            <span className="privacyDescSpan">Tung Chung, Hong Kong</span>
            <span className="privacyDescSpan">
              Tel: +852 2415 4777 | 2415 5177 | 2447 2000 | 2342 2000
            </span>
            <span className="privacyDescSpan">Fax: +852 2415 4977 </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
