import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "../../scss/include/customButtons.scss";

const CustomButtons = ({ height, width, color, text, submitting }) => {
  return (
    <>
      <Button
        className="customButtonMainDiv"
        style={{
          backgroundColor: color,
          width: width,
          height: height,
        }}
        disabled={submitting}
        type="submit"
      >
        <span>{text}</span>
        {submitting ? <Spinner animation="grow" color="primary" /> : null}
      </Button>
    </>
  );
};
export default CustomButtons;
