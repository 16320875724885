const hk = {
  home: "家",
  login: "登入",
  register: "註冊",
  en: "英文",
  hk: "香港",
  storeLocator: "商店定位器",
  bhavikaRestaurant: "Bhavika Restaurant",
  whatToBuy: "您今天想买什么？",
  //Login
  yourAccount: "您的帐户",
  emailAddress: "电子邮件地址",
  enterEmail: "输入电子邮件",
  password: "密码",
  enterPassword: "输入密码",
  forgotPassword: "忘记密码？",
  createAccount: "创建帐号",
  //SignUP
  signUp: "注册",
  toBhavika: "飞往巴哈维卡",
  name: "名称",
  enterName: "输入名字",
  mobileNumber: "手机号码（香港）",
  enterMobile: "输入手机号码（香港）",
  backToLogin: "回到登入",
  // Secondary Navbar
  categories: "分类目录",
  blogs: "网志",
  sellOnBhavika: "在Bhavika上出售",
  socialResponsibility: "社会责任感",
  help: "救命",
  getTheApp: "下载这个软件",

  shopByCategory: "按类别购物",
  topPicks: "热门精选",
  shopByBrands: "按品牌购物",
  featured: "精选",
  dealsYouCannotMiss: "您不能错过的交易",
  shopDeals: "店铺优惠",
  bestBuy: "百思买",
  // Footer
  whoWeAre: "我们是谁",
  whoWeAreContent2:
    "Bhavika商店是一家南亚杂货店，有成千上万的新鲜产品受到香港居民的喜爱。我们也为大小聚会提供餐饮服务。目前我们有两家商店。其中一间位于繁忙的将军澳区，另一间旗舰店位于香港东涌喜来登酒店内。",
  whoWeAreContent1: "香港Bhavika杂货店",
  mostSelling: "最畅销的产品",
  fruitsAndVegetables: "水果和蔬菜",
  ricePoha: "大米，波哈，粉丝",
  flour: "面粉",
  milkProducts: "奶制品",
  masalaAndSpices: "马萨拉和香料",
  snacks: "零食",
  hairProducts: "美发产品",
  aboutBhavika: "关于巴哈维卡",
  contactInformation: "联系信息",
  aboutUs: "关于我们",
  contactUs: "联系我们",
  dollarRewardSystem: "美元奖励制度",
  realTasteOfIndia: "真正的印度味道",
  deliverySchedule: "交货时间表",
  privacyPolicy: "隐私政策",
  advertiseWithUs: "与我们一起做广告",
  paymentMethods: "支付方式",
  address: "地址",
  callUsOn: "给我们打电话",
  fax: "传真",
  email: "电子邮件",
  workingDays: "工作日/小时",
  //
  profile: "个人资料",
  wishlist: "心愿单",
  logout: "登出",
  addToCart: "添加到购物车",
  addToWishlist: "加入心愿单",
  continueShopping: "继续购物",
  proceedToCart: "继续购物车并结帐",
  emptyCart: "空购物车",
  checkout: "查看",
  addNewAddress: "添加新地址",
  confirmOrderWithPayme: "与Payme确认订单",
  confirmOrderWithCOD: "货到付款的保密订单",
  haveCoupon: "有优惠券吗？",
  discountCode: "优惠码",
  apply: "应用",
  cancelOrder: "取消订单",

  // Checkout Page
  confirmDeliveryAddress: "确认收货地址",
  enterAddress: "输入地址",
  region: "地区",
  enterRegion: "输入地区",
  selectPaymentMethod: "选择付款方式",
  orderSummary: "订单摘要",

  save: "保存",
  update: "更新",
  submit: "提交",
  close: "关",
  cancel: "取消",
  select: "选择",
  // about us page
  whoWeArePara1:
    "Bhavika商店是香港著名的零售食品和杂货店。 拥有5,000多种产品和2个分支机构，您将找到想要的一切。 从新鲜的水果和蔬菜，大米和大麦，香料和调味料到包装产品，饮料，个人护理产品，我们一应俱全。",
  whoWeArePara2:
    "从每个类别的众多选项中进行选择，这些选项经过专门挑选，可帮助您以最低的价格找到最佳的质量。 您可以在商店内或在线进行购买，选择一个在每个地点指定最低金额的交货时间段，您的订单将直接发送到您家中的任何地方。",
  whoWeArePara3: "我们保证准时交货和最好的质量！",
  whoWeArePara4:
    "我们的总店位于豪华的香港东涌喜来登酒店内，我们设有茶点区，您可以在带有休息区的50多种美味佳肴中进行选择，这样您就可以尽情购物，然后坐下来放松身心。 令人垂涎的菜肴。",
  ourStores: "我们的商店",

  // contact us page
  getInTouch: "我想取得联系",
  contactUsPara1:
    "我们该怎样帮助你？ 如果您对我们有任何建议，技巧或问题，请分享。 这将有助于我们改善为您服务的方式。 还是您有投诉？ 请告知我们，以便我们尽快解决。",
  contactUsQ1: "我有一个一般性的建议或问题",
  contactUsQ2: "我对网上购物有疑问",
  contactUsQ3: "我对bhavika.online产品有疑问",
  contactUsQ4: "我有投诉",
  selectQuestion: "选择问题",
  phoneNumber: "电话号码",
  comment: "评论",

  //Dollar Reward System page
  howItWorks: "这个怎么运作",
  spendDollars: "花费美元赚取美元",
  howToSee: "如何查看我的奖励美元余额",
  howToRedeem: "如何兑换您的奖励美元",

  // return refund policy
  returnRefundPolicy: "退货和退款政策",
  testimonials: "我們的客戶怎麼說！",
};

export default hk;
