const en = {
  home: "Home",
  login: "Login",
  register: "Register",
  en: "EN",
  hk: "HK",
  storeLocator: "Store Locator",
  bhavikaRestaurant: "Bhavika Restaurant",
  whatToBuy: "What would you like to buy today?",
  //Login
  yourAccount: "your account",
  emailAddress: "Email Address",
  enterEmail: "Enter Email",
  password: "Password",
  enterPassword: "Enter Password",
  forgotPassword: "Forgot Password?",
  createAccount: "Create Account",
  //SignUP
  signUp: "SignUp",
  toBhavika: "to Bhavika",
  name: "Name",
  enterName: "Enter Name",
  mobileNumber: "Mobile Number(HK)",
  enterMobile: "Enter Mobile Number(HK)",
  backToLogin: "Back to login",
  // Secondary Navbar
  categories: "Categories",
  blogs: "Blogs",
  sellOnBhavika: "Sell On Bhavika",
  socialResponsibility: "Social Responsibility",
  help: "Help",
  getTheApp: "Get The App",

  shopByCategory: "Shop By Category",
  topPicks: "Top Picks",
  shopByBrands: "Shop By Brands",
  featured: "Featured",
  dealsYouCannotMiss: "Deals You Cannot Miss",
  shopDeals: "Shop Deals",
  bestBuy: "Best Buy",
  // Footer
  whoWeAre: "Who We Are",
  whoWeAreContent1: "Bhavika Grocery Store in Hong Kong",
  whoWeAreContent2:
    "Bhavika Store is a South Asian Grocery store with thousands of freshly shipped products loved by residents in Hong Kong. We do catering for big and small parties as well.",
  whoWeAreContent3:
    "Presently we have two stores. One in the busy Tseung Kwan O district and the other flagship store inside the Sheraton HK Tung Chung Hotel.",
  mostSelling: "Most Selling Products",
  fruitsAndVegetables: "Fruits And Vegetables",
  ricePoha: "Rice, Poha, Vermicelli",
  flour: "Flour",
  milkProducts: "Milk Products",
  masalaAndSpices: "Masala And Spices",
  snacks: "Snacks",
  hairProducts: "Hair Products",
  aboutBhavika: "About Bhavika",
  contactInformation: "Contact Information",
  aboutUs: "About Us",
  contactUs: "Contact Us",
  realTasteOfIndia: "Real Taste Of India",
  dollarRewardSystem: "Dollar Reward System",
  deliverySchedule: "Delivery Schedule",
  privacyPolicy: "Privacy Policy",
  advertiseWithUs: "Advertise With Us",
  paymentMethods: "Payment Methods",
  address: "Address",
  callUsOn: "Call Us On",
  fax: "Fax",
  email: "Email",
  workingDays: "Working Days/Hours",
  //
  profile: "Profile",
  wishlist: "Wishlist",
  logout: "Logout",
  addToCart: "Add to Cart",
  addToWishlist: "Add to Wishlist",
  continueShopping: "Continue Shopping",
  proceedToCart: "Proceed to cart & checkout",
  emptyCart: "Empty Cart",
  checkout: "Check-Out",
  addNewAddress: "Add New Address",
  confirmOrderWithPayme: "Confirm Order With Payme",
  confirmOrderWithCOD: "Confrim Order With Cash On Delivery",
  haveCoupon: "Have Coupon?",
  discountCode: "Discount Code",
  apply: "Apply",
  cancelOrder: "Cancel Order",

  // Checkout Page
  confirmDeliveryAddress: "Confirm Delivery Address",
  enterAddress: "Enter Address",
  region: "Region",
  enterRegion: "Enter Region",
  selectPaymentMethod: "Select Payment Method",
  orderSummary: "Order Summary",

  save: "Save",
  update: "Update",
  submit: "Submit",
  close: "Close",
  cancel: "Cancel",
  select: "Select",
  // about us page
  whoWeArePara1:
    "Bhavika Store is a reputed retail food and grocery store in Hong Kong. With over 5,000 products and 2 branches you will find everything you are looking for. Right from fresh fruits and vegetables, rice and dals, spices and seasonings to packaged products, beverages, personal care products – we have it all.",
  whoWeArePara2:
    "Choose from a wide range of options in every category, exclusively handpicked to help you find the best quality available at the lowest prices. You can make your purchases either in-store or online, select a time slot for delivery with a designated minimum amount per location and your order will be delivered right to your doorstep, anywhere in Hong Kong.",
  whoWeArePara3: "We guarantee on time delivery and the best quality!",
  whoWeArePara4:
    "Our main store is located inside the posh Sheraton HK Tung Chung Hotel and we have a light refreshment section where you have a choice of over 50 delicious items with a sitting area so you can shop to your heart's content and then sit back and relax with our mouth watering dishes.",
  ourStores: "Our Stores",

  // contact us page
  getInTouch: "I would like to get in touch",
  contactUsPara1:
    "How can we help you? If you have any suggestions, tips or questions for us please do share. That would help us to improve the way we serve you. Or do you have a complaint? Please let us know so that we can address it promptly.",
  contactUsQ1: "I have a general suggestion or question",
  contactUsQ2: "I have a question about online shopping",
  contactUsQ3: "I have a question about bhavika.online products",
  contactUsQ4: "I have a complaint",
  selectQuestion: "Select Question",
  phoneNumber: "Phone Number",
  comment: "Comment",

  //Dollar Reward System page
  howItWorks: "How It Works",
  spendDollars: "Spend Dollars to Earn Dollars",
  howToSee: "How to See My Reward Dollar Balance",
  howToRedeem: "How to Redeem your Reward Dollar",

  // return refund policy
  returnRefundPolicy: "Return & Refund Policy",
  testimonials: "What Our Customer Say’s!",
};

export default en;
