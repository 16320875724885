import React, { createContext, useState } from "react";

export const BrandContext = createContext();

const BrandContextProvider = (props) => {
  const [brand, setBrand] = useState([]);

  return (
    <BrandContext.Provider value={{ brand, setBrand }}>
      {props.children}
    </BrandContext.Provider>
  );
};

export default BrandContextProvider;
