import React from "react";
import "../../scss/include/socialResponsibility.scss";
// import BreadcrumbComponent from '../CustomComponents/BreadcrumbComponent';
import DownloadOurAPpImgComponent from "../CustomComponents/DownloadOurAPpImgComponent";

const SocialResponsibility = () => {
  return (
    <>
      {/* <BreadcrumbComponent from="Home" to="Social Resopnsibility"/> */}
      {/* <div className="container-fluid p-0">
            <div className="row p-0">
                <div className="col-lg-12 col-md-12 col-12 bg-danger">
                SocialResponsibility
                </div>
            </div>
        </div> */}
      <div className="jumbotron jumbotron-fluid jumbotronMainDiv d-flex flex-column justify-content-center align-items-center">
        <div className="container d-flex flex-column justify-content-center align-items-center">
          <h1 className="socialResponsibilityheadingText">
            Social Responsibility
          </h1>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <p className="socialResponsibilityText">
              Bhavika Store collaborates with charitable organizations to
              provide special discounts to needy families who are identified by
              the government.
            </p>
          </div>
        </div>
      </div>

      <DownloadOurAPpImgComponent />
    </>
  );
};
export default SocialResponsibility;
