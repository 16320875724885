import React from "react";
import useTrans from "../hooks/useTrans";

const OurIntroduction = () => {
  const trans = useTrans();
  return (
    <div className="container-fluid ">
      <span className="font-weight-bold title">{trans.whoWeAre}?</span>
      <p className={"desc mt-2"}>{trans.whoWeArePara1}</p>
      <p className={"desc"}>{trans.whoWeArePara2}</p>
      <p className={"desc"}>{trans.whoWeArePara3}</p>
      <p className={"desc"}>{trans.whoWeArePara4}</p>
    </div>
  );
};
export default OurIntroduction;
