import React from "react";
import "../../scss/include/inStoreAdvertisment.scss";
import AdvertismentTypeCard from "../CustomComponents/AdvertismentTypeCard";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import ShopByHeading from "../CustomComponents/ShopByHeading";
import InDoorAdvImg from "../../assets/Others/trollyAdv.jpg";
import InDoorAdvImg2 from "../../assets/Others/standeesAdv.jpg";
import InDoorAdvImg3 from "../../assets/Others/Danglers.jpg";
import AdvertismentTypeHeaderSection from "../CustomComponents/AdvertismentTypeHeaderSection";

const InStoreAdvertisment = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Advertise with us"
        toHref="/advertise-with-us"
        thirdPage="In Store Advertisment"
        thirdPageHref="/advertise-with-us/Store"
      />

      <AdvertismentTypeHeaderSection />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column justify-content-center ">
            <span className="advDescInStore">
              Bhavika Grocery offers various in-store visibility options
              strategically placed across store. These branding elements provide
              high visibility to brands during the shopper journey. Advertisers
              can customize their brand communication and create awareness for
              their brand through these elements.
            </span>
            <span className="advDescInStore mt-2">
              To know more about the InStore advertising, please feel free to
              contact us via email:{" "}
              <a
                href="mailto:info@bhavika.online"
                style={{ textDecoration: "underline" }}
              >
                info@bhavika.online
              </a>
            </span>
            {/* <span className="advDescInStore mt-2">The various visibility elements available are</span> */}
          </div>
        </div>
      </div>
      <ShopByHeading title="In-Store Advertisment Image" />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 flex-wrap d-flex h-auto ">
            <AdvertismentTypeCard
              imgName={InDoorAdvImg}
              contain="Advertisment in Trolly"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg2}
              contain="Advertisment in Standees"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg3}
              contain="Advertisment in Danglers"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg}
              contain="Advertisment in Trolly"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg2}
              contain="Advertisment in Standees"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg3}
              contain="Advertisment in Danglers"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg2}
              contain="Advertisment in Standees"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg3}
              contain="Advertisment in Danglers"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg}
              contain="Advertisment in Trolly"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg3}
              contain="Advertisment in Danglers"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg2}
              contain="Advertisment in Standees"
            />
            <AdvertismentTypeCard
              imgName={InDoorAdvImg}
              contain="Advertisment in Trolly"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InStoreAdvertisment;
