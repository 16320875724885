import React, { useContext, useEffect } from "react";
import "../../scss/include/brandHomePageComponent.scss";
import api from "../../services/api";
import { imageUrl } from "../../utils/helper";
import { BrandContext } from "../Context/Brand";
import houseHoldIcon from "../../assets/img/imported.svg"
import ayurvedaIcon from "../../assets/img/ayurbala.jpeg"
 
const BrandHomePageComponent = () => {
  const { brand, setBrand } = useContext(BrandContext);

  useEffect(() => {
    async function getBrand() {
      const brandRes = await api.get("/api/v1/brands");
      console.log(brandRes);
      if (brandRes && brandRes.data) {
        setBrand(brandRes.data);
      }
    }
    getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newArray = brand;

  const filterData = () => {
    brand &&
      brand.filter((product) => {
        if (product.name === "Ganesh Murti") {
          newArray.pop();
          const length = newArray.length;
          newArray.splice(length / 2, 0, product);
        }
      });
  };

  useEffect(() => {
    filterData();
  }, [brand]);

  // console.log(filterData);
  // console.log(newArray);

  return (
    <>
      <div className="container-fluid brandHomePageComponentMainDiv">
        <div className="row p-0 h-100 innerRow d-flex justify-content-between align-items-center my-2">
          {newArray.slice(0).reverse().map((data, index) => 
          {
            if(data.slug !== 'britannia' && data.slug !== 'beverages-and-juices' && data.slug !== 'bikaji-frozen-foods' ){
              
              return (
                <div
                key={index}
                className="col-1 p-0 d-flex justify-content-center align-items-center"
              >
                <a
                  href={`/brand/${data.slug}`}
                >
                  <img
                    src={imageUrl(data.logo)}
                    alt="brand-logo"
                  />
                </a>
              </div>  
              )
            }
          }
          )}
          <div
                className="col-1 p-0 d-flex justify-content-center align-items-center"
              >
                <a
                  href={`https://www.bhavika.online/category/personal-care/ayurveda-products`}
                  style={{ color: 'inherit', fontSize: '8px', textAlign: 'center', letterSpacing: '-0.05em', lineHeight: '8px' }}
                >
                  <img
                    src={ayurvedaIcon}
                    alt="brand-logo"
                  />
                  {/* <p>Imported Beverages<br />&amp; Juices</p> */}
                </a>
              </div>
          <div
                className="col-1 p-0 d-flex justify-content-center align-items-center"
              >
                <a
                  href={`/brand/${newArray[11]?.slug}`}
                  style={{ color: 'inherit', fontSize: '8px', textAlign: 'center', letterSpacing: '-0.05em', lineHeight: '8px' }}
                >
                  <img
                    src={imageUrl(newArray[11]?.logo)}
                    alt="brand-logo"
                    style={{ maxHeight: '30px' }}
                  />
                  <p>Imported Beverages<br />&amp; Juices</p>
                </a>
              </div>
          <div
              className="col-1 p-0 d-flex justify-content-center align-items-center"
            >
              <a
                href={`/category/household/imported-household-items`}
                style={{ color: 'inherit', fontSize: '8px', textAlign: 'center', letterSpacing: '-0.05em', lineHeight: '8px' }}
              >
                <img
                  src={houseHoldIcon}
                  alt="brand-logo"
                  style={{ maxHeight: '30px' }}
                />
                <p>Imported Household<br />Items</p>
              </a>
            </div>
        </div>
      </div>
    </>
  );
};
export default BrandHomePageComponent;
