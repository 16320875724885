import React from "react";

import "../../scss/include/blogCard.scss";
// import BlogImg from "../../assets/BlogPost/dal_makahani.jpg";

import { imageUrl } from "../../utils/helper";

const BlogCard = ({ posts }) => {
  return (
    <>
      {posts.map((post, index) => (
        <div key={index} className="col-12 col-sm-8 col-md-6 col-lg-4 mb-3">
          <div className="card h-100">
            <a
              href={`/blog/${post.slug}`}
              className="d-flex justify-content-center"
            >
              <img
                className="card-img"
                src={imageUrl(post.banner)}
                alt="banner"
              />
            </a>

            <div className="card-body">
              <h4 className="card-title">{post.title}</h4>
              <small className="text-muted cat">
                <i className="fas fa-user text-primary mr-1"></i>{" "}
                {post.user && post.user.name ? post.user.name : post.user}
              </small>
              <p className="card-text">{post.description}</p>
              <a href={`/blog/${post.slug}`} className="btn btn-primary">
                Read More
              </a>
            </div>
            <div className="card-footer text-muted bg-transparent border-top-0">
              {new Date(post.updatedAt).toLocaleString()}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default BlogCard;
