import React, { useState } from "react";
import "../../scss/include/backToTopButton.scss";

const BackToTop = () => {
  const [state, setState] = useState(false);
  // var url = window.location.href;
  // var url = window.location.pathname;
  // var backToTopUrl = `${url}#top`;
  // var backToTopUrl = `${url}#top`;

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      // mybutton.style.display = "block";
      setState(true);
    } else {
      // mybutton.style.display = "none";
      setState(false);
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {state ? (
        <>
          <div className="container-fluid">
            <div className="row  d-flex justify-content-end align-items-center">
              <div className="col-lg-2 col-md-2 col-2  d-flex justify-content-center align-items-center">
                <div
                  className="backToTopMainDiv d-flex justify-content-center align-items-center"
                  onClick={scrollTop}
                >
                  <i className="fas fa-angle-up"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default BackToTop;
