import React, { useEffect, useState } from "react";
import { Alert, Button, ListGroup, Modal, Spinner } from "react-bootstrap";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";
import PageSpinner from "../CustomComponents/PageSpinner";

import "../../scss/include/MyOrderDetailsById.scss";

// import payMeImg from "../../assets/icons/payme.png";
// import pavImg from "../../assets/icons/pay.svg";
// import aliPayImg from "../../assets/icons/alipay.png";
// import payPalImg from "../../assets/icons/paypal.svg";
import hsbcImage from "./../../assets/icons/hsbc.png";
import payMeBtnImg from "./../../assets/icons/paymebtn.png";
import imageLogo from "./../../assets/img/logo.png";
import { update_qrcode } from "../../payme";
import { useHistory } from "react-router-dom";

function MyOrderDetailsById(props) {
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const [order, setOrder] = useState({
    orderItems: [],
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const [paymeLoading, setPaymeLoading] = useState(false);

  const [displayQr, setDisplayQr] = useState(false);

  const [showCancelOrder, setShowCancelOrder] = useState(false);

  const [cancelOrderResponse, setCancelOrderResponse] = useState("");

  const closeCancelOrder = () => {
    setCancelOrderError("");
    setShowCancelOrder(false);
  };

  const [cancelOrderError, setCancelOrderError] = useState("");

  const cancelOrderHandler = async (orderId) => {
    try {
      const res = await api.get(`/api/v1/user/order/cancel/${orderId}`);
      if (res && res.ok) {
        setShowCancelOrder(false);
        setCancelOrderResponse(res.message);
        setTimeout(() => {
          setCancelOrderResponse("");
        }, 5000);
      }
    } catch (error) {
      consoleError(error);
      setCancelOrderError(
        error && error.message ? error.message : "Error canceling the order"
      );
    }
  };

  const downloadInvoice = async (orderId) => {
    setDownloading(true);
    try {
      const res = await api.get(`/api/v1/user/order/invoice/${orderId}`, {
        responseType: "blob",
      });
      //   const blob = await res.blob();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${order.serialId ? order.serialId : orderId}.pdf`;
      a.click();
    } catch (error) {
      consoleError(error);
    } finally {
      setDownloading(false);
    }
  };

  const sendPaymentRequest = async (e) => {
    setPaymeLoading(true);
    try {
      if (!order && !order._id) {
        setErrorMessage("Error with getting order. Please try again");
        return;
      }
      const res = await api.post("/api/v1/user/payment/payme/init", {
        orderId: order._id,
        mobile: false,
      });
      if (res && res.ok) {
        const paymentData = res.data;
        setDisplayQr(true);
        setPaymeLoading(false);
        setMessage(
          "The Payment has been Initiated. Please do not refresh or close this page."
        );
        update_qrcode(paymentData.webLink, 0, "Q");
        const paymeStatusInterval = setInterval(async () => {
          try {
            const res = await api.get(
              `/api/v1/user/payment/payme/status/${paymentData.paymentRequestId}`
            );
            if (res && res.ok) {
              if (res.data.statusCode === "PR005") {
                setDisplayQr(false);
                setSuccessMessage(
                  "The Payment has been done successfully and order has been made."
                );
                setMessage("");
                setErrorMessage("");
                window.location.reload();
                clearInterval(paymeStatusInterval);
              } else if (res.data.statusCode === "PR001") {
                setMessage(
                  "The Payment has been Initiated. Please do not refresh or close this page."
                );
                setSuccessMessage("");
                setErrorMessage("");
              } else if (res.data.statusCode === "PR007") {
                setErrorMessage(
                  "The Payment request has been expired. Please reload the page and try again."
                );
                clearInterval(paymeStatusInterval);
                setMessage("");
                setSuccessMessage("");
                setDisplayQr(false);
                window.location.reload();
              }
            }
          } catch (error) {
            consoleError(error);
          }
        }, 20000);
      }
    } catch (error) {
      consoleError(error);
      setPaymeLoading(false);
    }
  };
  const sendPaymentRequestMobile = async (e) => {
    try {
      if (!order && !order._id) {
        setErrorMessage("Error with getting order. Please try again");
        return;
      }
      const res = await api.post("/api/v1/user/payment/payme/init", {
        orderId: order._id,
        mobile: true,
      });
      if (res && res.ok) {
        const paymentData = res.data;
        window.location.href = paymentData.webLink;
      }
    } catch (error) {
      consoleError(error);
    }
  };

  const {
    match: { params },
  } = props;
  const { id } = params;

  async function getMyOrderById() {
    setPageLoading(true);
    try {
      const res = await api.get(`/api/v1/user/order/${id}`);
      if (res && res.ok) {
        setOrder(res.data);
      }
      if (!res || (res && !res.data) || (res.data && !res.data._id)) {
        history.push("/404");
      }
    } catch (error) {
      consoleError(error);
    } finally {
      setPageLoading(false);
    }
  }
  useEffect(() => {
    getMyOrderById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center mt-5">
        {pageLoading ? (
          <div>
            <PageSpinner />
          </div>
        ) : order && order._id ? (
          <div className="col-md-8 col-lg-8 col-sm-12 col-12">
            {cancelOrderResponse ? (
              <Alert
                variant="info"
                size="sm"
                className="text-center mt-3 mb-3"
                onClose={() => setCancelOrderResponse("")}
                dismissible
              >
                {cancelOrderResponse}
              </Alert>
            ) : null}
            <div className="orderSummary">
              <div className="orderHeader d-flex justify-content-between">
                <div className="orderHeaderTitle">
                  <h4>
                    Order Summary <div className="borderBottom"></div>
                  </h4>
                  <h6>
                    Order ID: {order.serialId ? order.serialId : order._id}
                  </h6>
                </div>
                <div>
                  {order &&
                  order.paidStatus !== "paid" &&
                  (order.status === "stock allocated" ||
                    order.status === "processing") ? (
                    <Button
                      variant="danger"
                      className="rounded-pill mr-2"
                      size="sm"
                      onClick={() => setShowCancelOrder(true)}
                    >
                      Cancel Order
                    </Button>
                  ) : null}
                  <Button
                    variant="dark"
                    className="rounded-pill"
                    size="sm"
                    disabled={downloading}
                    onClick={() => downloadInvoice(order._id)}
                  >
                    {downloading ? (
                      <Spinner animation="border" size="sm"></Spinner>
                    ) : (
                      <>
                        <i className="fa fa-file-pdf"></i> Download Invoice
                      </>
                    )}
                  </Button>
                </div>
              </div>
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th className="text-left">Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems.map((item, index) => (
                    <tr key={index}>
                      <td className="text-left">{item.name}</td>
                      <td>${Number(item.price).toFixed(2)}</td>
                      <td>{item.quantity}</td>
                      <td>${Number(item.price * item.quantity).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr className="table-borderless">
                    <td colSpan="4" />
                  </tr>
                  <tr className="table-borderless">
                    <td></td>
                    <td></td>
                    <td>Shipping Price</td>
                    <td colSpan={3}>
                      ${Number(order.shippingPrice).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="table-borderless">
                    <td></td>
                    <td></td>
                    <td>Discount</td>
                    <td colSpan={3}>${Number(order.discount).toFixed(2)}</td>
                  </tr>
                  <tr className="table-borderless">
                    <td></td>
                    <td></td>
                    <td>Reward</td>
                    <td colSpan={3}>${Number(order.rewardPrice).toFixed(2)}</td>
                  </tr>
                  <tr className="table-borderless">
                    <td></td>
                    <td></td>
                    <td className="font-weight-bold">Total</td>
                    <td colSpan={3} className="font-weight-bold">
                      ${Number(order.totalPrice).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="shippingDetails">
              <h4>Shipping Details</h4>
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th className="text-left">Full Name</th>
                    <th>Address</th>
                    <th>Region</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">{order.shippingAddress.name} </td>
                    <td>{order.shippingAddress.address} </td>
                    <td>{order.shippingAddress.region} </td>
                    <td>{order.shippingAddress.phone} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="status">
              <h4>Status</h4>
              <ListGroup horizontal="sm" className="justify-content-center">
                <ListGroup.Item>
                  Payment Method: {order.paymentMethod}
                </ListGroup.Item>
                <ListGroup.Item>
                  Payment Status: {order.paidStatus}
                </ListGroup.Item>
                <ListGroup.Item>Order Status: {order.status}</ListGroup.Item>
              </ListGroup>
            </div>
            {order.paymentMethod === "payme" &&
            order.paidStatus !== "paid" &&
            order.status !== "failed" &&
            order.status !== "cancelled" ? (
              <div className="payment payme-section">
                <div className="form-submit text-center">
                  {/*<h4 style={{textTransform: 'none'}} className={this.state.expiryMessage ? 'd-block text-danger text-center' : 'd-none'}>{this.state.expiryMessage}</h4>*/}
                  {errorMessage && (
                    <h4
                      style={{ textTransform: "none" }}
                      className={"d-block text-center text-danger bg-grey"}
                    >
                      {errorMessage}
                    </h4>
                  )}
                  {successMessage && (
                    <h4
                      style={{ textTransform: "none" }}
                      className={"d-block text-center text-success bg-grey"}
                    >
                      {successMessage}
                    </h4>
                  )}
                  <p className="pay-desc">
                    <img src={hsbcImage} alt="hsbc" />
                  </p>
                  <div className="text-center">
                    {message ? (
                      <h6 className={"text-center"}>{message}</h6>
                    ) : null}

                    {paymeLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        variant="danger"
                      >
                        {/* <span className="sr-only">Loading...</span> */}
                      </Spinner>
                    ) : (
                      <div>
                        <div
                          className={
                            displayQr
                              ? "d-none"
                              : "pay-btn d-none d-md-block d-lg-block d-xl-block"
                          }
                          onClick={(e) => sendPaymentRequest(e)}
                        >
                          <img src={payMeBtnImg} alt="payme-btn" />
                        </div>
                        <div
                          className={
                            "d-block pay-btn d-md-none d-lg-none d-xl-none"
                          }
                          onClick={(e) => sendPaymentRequestMobile(e)}
                        >
                          <img src={payMeBtnImg} alt="payme-btn" />
                        </div>
                      </div>
                    )}
                  </div>
                  <canvas
                    style={
                      displayQr ? { display: "inline" } : { display: "none" }
                    }
                    id="payCodeCanvas"
                    width="344"
                    height="344"
                  />
                  <div className="row">
                    <div className="col-lg-3 col-xl-4 d-none d-lg-block d-xl-block" />
                    <div
                      className="col-lg-6 col-xl-4 col-md-12 col-sm-12 col-12"
                      style={
                        message ? { display: "none" } : { display: "inline" }
                      }
                    >
                      <img
                        className={"d-none"}
                        id="logo"
                        src={imageLogo}
                        alt="logo-qr"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-xl-4 col-md-12 col-sm-12 col-12">
                      <ul className="payme-steps">
                        <li className="payment-steps">Paying With PayMe</li>
                        <li className="paymeStep">
                          <span className="numberCircle one"></span>Open the
                          PayMe app
                        </li>
                        <li className="paymeStep">
                          <span className="numberCircle two"></span>Scan the
                          PayCode to authorise payment.
                        </li>
                        <li className="paymeStep">
                          <span className="numberCircle three"></span>Complete
                          payment in the app and wait for the confirmation
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <h4 className="text-muted">
            Error getting order. Try{" "}
            <Button
              size="sm"
              onClick={() => window.location.reload()}
              variant="info"
              className="rounded-pill"
            >
              Refresh Page.
            </Button>
          </h4>
        )}
      </div>

      <Modal
        size="md"
        show={showCancelOrder}
        onHide={closeCancelOrder}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>Are you sure you want to cancel the order?</h5>
          <button
            className="btn btn-outline-secondary btn-circle"
            onClick={closeCancelOrder}
          >
            <i className="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-sm-12">
              Please be sure before cancelling the order.
            </div>
            {cancelOrderError ? (
              <Alert
                variant="danger"
                className="text-center mt-3 mb-0"
                onClose={() => setCancelOrderError("")}
                dismissible
              >
                {cancelOrderError}
              </Alert>
            ) : null}
          </div>
          <div className="container mt-4 mb-3 d-flex justify-content-end flex-wrap">
            <Button
              variant="danger"
              className="rounded-pill mr-2"
              size="sm"
              onClick={() => cancelOrderHandler(order._id)}
            >
              {"Cancel Order"}
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="rounded-pill"
              onClick={closeCancelOrder}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyOrderDetailsById;
