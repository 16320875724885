import React from "react";
import "../../scss/include/paymentMethodCard.scss";
import { Link } from "react-router-dom";

const PaymentMethodCard = () => {
  return (
    <>
      <div className="container-fluid  mb-3">
        <div className="row">
          <div className="col-lg-3 col-md-3  col-6 d-flex justify-content-center align-items-center pmCard">
            <Link to="/checkout/creditcard" className="linkWithPaymentOption">
              <i className="fa fa-credit-card fa-4x" aria-hidden="true"></i>
              Credit Card
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  d-flex justify-content-center align-items-center pmCard">
            <Link to="/checkout/payme" className="linkWithPaymentOption">
              <i className="fa fa-credit-card fa-4x" aria-hidden="true"></i>
              Pay Me
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  d-flex justify-content-center align-items-center pmCard">
            <Link to="/checkout/alipay" className="linkWithPaymentOption">
              <i
                className="fab fa-alipay fa-4x"
                style={{ color: "#00A1E9" }}
              ></i>
              Alipay
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  d-flex justify-content-center align-items-center pmCard">
            <Link to="/checkout/paypal" className="linkWithPaymentOption">
              <i
                className="fab fa-paypal fa-4x"
                style={{ color: "#028CD4" }}
              ></i>
              Pay pal
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentMethodCard;
