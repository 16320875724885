import React from 'react';
import AdvertismentTypeHeaderSection from '../CustomComponents/AdvertismentTypeHeaderSection';
import BreadcrumbComponent from '../CustomComponents/BreadcrumbComponent';
import '../../scss/include/specialEventsAdvertisment.scss';
import img1 from '../../assets/Others/img1.jpg';
import img2 from '../../assets/Others/img2.jpg';
import img3 from '../../assets/Others/img3.jpg';
import img4 from '../../assets/Others/img4.jpg';
import img5 from '../../assets/Others/img5.jpg';
import img6 from '../../assets/Others/img6.jpg';
import LazyLoad from 'react-lazyload';


const SpecialEventsAdvertisment = () =>{
    return(
        <>
        <BreadcrumbComponent from="Home" fromHref="/" to="Advertise with us" toHref="/advertise-with-us" thirdPage="Special Events Advertisment" thirdPageHref="/advertise-with-us/SpecialEvents" />
        <AdvertismentTypeHeaderSection/>
        <div className="container p-0">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
                    <span className="specialEventHeaderText">
                    Established BRANDED PROPERTIES with unique consumer value proposition
                    </span>
                </div>
            </div>
        </div>
        <div className="container mt-3 p-0">
            <div className="row p-0">
                <div className="col-lg-6 col-md-6 col-12 d-flex flex-column  align-items-center">
                    <div className="div1 w-100 h-auto d-flex flex-column mb-3">
                        <span className="mb-1 headingTopSE">Regional and National Customization with the Indian Household Shopping Calendar</span>
                        <span className="descSE">Weekly, Monthly, Annual Properties based on national and diverse regional shopping habits, occasions and practices</span>
                    </div>
                    <div className="div1 w-100 h-auto d-flex flex-column mb-3">
                        <span className="mb-1 headingTopSE">{`Leverages Core Indian Consumer Shopping Behaviour & Insights`}</span>
                        <span className="descSE">Proprietary Properties, Loyalty programme, Offers and promotions based on powerful consumer insights which make them feel proud and extremely satisfied with each purchase</span>
                    </div>
                    <div className="div1 w-100 h-auto d-flex flex-column mb-3">
                        <span className="mb-1 headingTopSE">Maximises Consumer Convenience</span>
                        <span className="descSE">Convenient Store location at Entertainment Hubs and Family Destination Centres attracts millennials and families</span>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12  flex-wrap imgDivSE">
                    <LazyLoad>
                    <img src={img1} className="img-fluid m-1" alt="Special Event"/>
                    <img src={img2} className="img-fluid m-1" alt="Special Event"/>
                    <img src={img3} className="img-fluid m-1" alt="Special Event"/>
                    <img src={img4} className="img-fluid m-1" alt="Special Event"/>
                    <img src={img5} className="img-fluid m-1" alt="Special Event"/>
                    <img src={img6} className="img-fluid m-1" alt="Special Event"/>
                    </LazyLoad>
                </div>
            </div>
        </div>
        </>
    )
}
export default SpecialEventsAdvertisment;