import React, { useContext } from "react";
import "../../scss/include/dealsYouCannotMissCard.scss";
import { PromotionContext } from "../Context/Promotion";

import { imageUrl } from "../../utils/helper";

import LazyLoad from "react-lazyload";
import useTransPara from "../hooks/useTransPara";
import useTrans from "../hooks/useTrans";
import OfferEndedTag from "../../assets/Others/OfferEndedTag.png";

const DealsYouCannotMissCard = () => {
  const { promotion } = useContext(PromotionContext);

  const transName = useTransPara("name");
  const transDescription = useTransPara("description");
  const trans = useTrans();

  return (
    <>
      <div className="deal-section container-fluid">
        <div className="row">
          {promotion.map((item, index) => {
            let expiredFlag = false;
            const currentDateAndTime = new Date().toLocaleString("default", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            });
            // console.log(currentDateAndTime);
            const getExpiryData = new Date(item.expiryDate).toLocaleString(
              "default",
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }
            );
            // console.log(getExpiryData);
            if (currentDateAndTime < getExpiryData) {
              // console.log('Expiry Date is greater');
              expiredFlag = false;
            } else {
              // console.log('Current Date is greater');
              expiredFlag = true;
            }

            return (
              <div className="col-md-12 col-sm-12 col-lg-6" key={item._id}>
                <a href={`/promotion/${item.slug}`}>
                  <div className="brand-block" id={`block${(index % 4) + 1}`}>
                    <div className="row">
                      <div className="col-md-8 col-sm-12 p-0">
                        {expiredFlag ? (
                          <img
                            src={OfferEndedTag}
                            className="img-fluid"
                            alt="offer_expiry_tag"
                            style={{
                              position: "absolute",
                              left: "0",
                              width: "150px",
                              height: "50px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <div className="text-section white-text m-lg-0 my-5">
                          <h2>{transName(item)}</h2>
                          <p>{transDescription(item)}</p>
                          <button className="btn btn-round">
                            {trans.shopDeals}
                          </button>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 d-lg-block -d-md-block d-xl-block d-sm-block d-none">
                        <div className="img-section">
                          <LazyLoad height={100}>
                            <img
                              src={imageUrl(item.thumbnail)}
                              alt="deals"
                              loading="lazy"
                            />
                          </LazyLoad>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default DealsYouCannotMissCard;
