import React, { useEffect, useState } from "react";
import "../../scss/include/testimonialsCard.scss";
import ReactPlayer from "react-player/youtube";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import api from "../../services/api";
import { imageUrl } from "../../utils/helper";
// import { UPLOAD_URL } from "src/config";

const TestimonialsCard = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    async function getTestimonials() {
      const testimonialRes = await api.get("/api/v1/testimonials");
      if (testimonialRes && testimonialRes.data) {
        setTestimonials(testimonialRes.data);
      }
    }
    getTestimonials();
  }, []);

  return (
    <div className="container testimonialsContainer pb-5">
      <div className="row">
        <Swiper
          pagination={{
            type: "fraction",
          }}
          navigation={true}
          autoHeight={true}
          modules={[Pagination, Navigation]}
        >
          {testimonials.map((item) => {
            const hasDescription =
              item.description && item.description.length > 0;
            const hasTitle = item.name && item.name.length > 0;
            const hasDesignation = item.address && item.address.length > 0;
            const hasLargeContent =
              !hasDescription && !hasTitle && !hasDesignation;

            return (
              <SwiperSlide className="col-12" key={item._id}>
                <div
                  className={`card testimonial-card ${
                    hasLargeContent ? "large-content" : ""
                  }`}
                >
                  <div className="card-body">
                    <img src="comma.png" className="comma" alt="" />

                    <div className="row testimonial-content">
                      <div className="col-12">
                        {item.description && (
                          <div className="testimonial-description">
                            {item.description}
                          </div>
                        )}

                        {item.name && (
                          <div className="testimonial-title">{item.name}</div>
                        )}

                        {item.address && (
                          <h6 className="testimonial-designation">
                            {item.address}
                          </h6>
                        )}
                      </div>

                      <div className="row">
                        {item.images && item.images.length > 0 && (
                          <div>
                            <div
                              className={`testimonial-images ${
                                hasLargeContent ? "large-size" : ""
                              }`}
                            >
                              {item.images.map((image, index) => {
                                return (
                                  <img
                                    key={index}
                                    src={imageUrl(image)}
                                    className="testimonial-image"
                                    alt=""
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}

                        {item.link && (
                          <div>
                            <ReactPlayer
                              responsive={"true"}
                              url={item.link}
                              controls={true}
                              className={`testimonial-video ${
                                hasLargeContent ? "large-size" : ""
                              }`}
                              width={hasLargeContent ? "480px" : "240px"}
                              height={hasLargeContent ? "300px" : "150px"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default TestimonialsCard;
