import React from "react";
import downloadAppImg from "../../assets/Others/website download section.jpg";
import "../../scss/include/downloadOurAPpImgComponent.scss";
import LazyLoad from "react-lazyload";

const DownloadOurAPpImgComponent = () => {
  return (
    <>
      <LazyLoad height={200}>
        <div className="container-fluid app-img">
          <a href="#PlayStore">
            <img
              src={downloadAppImg}
              className={"img-fluid"}
              loading="lazy"
              alt="Downloads App"
            />
          </a>
        </div>
      </LazyLoad>
    </>
  );
};
export default DownloadOurAPpImgComponent;
