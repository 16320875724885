import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import loginImg from "../../assets/loginAndSignupModule/login image.jpg";
// import signUpImg from "../../assets/loginAndSignupModule/signup image.jpg";
import logo from "../../assets/img/bhavikaLogo.png";
import cutleryImg from "../../assets/icons/cutlery.png";


import CustomButtons from "../CustomComponents/CustomButtons";
// import FbLoginButton from "../CustomComponents/FbLoginButton";
// import GmailLoginButton from "../CustomComponents/GmailLoginButton";
import SuccessMessage from "../CustomComponents/SuccessMessage";
import FailedMessage from "../CustomComponents/FailedMessage";
import BackToTop from "../CustomComponents/BackToTopButton";

import { API_BASE_URL } from "../../config";
import api from "../../services/api";
import axios from "axios";
import { CartContext } from "../Context/Cart";
import { Badge, Dropdown, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import GoogleLoginButton from "../CustomComponents/GoogleLoginButton ";
import FacebookLoginButton from "../CustomComponents/FacebookLoginButton";
import {
  consoleError,
  consoleLog,
  emailIsValid,
  getFirstName,
} from "../../utils/helper";
import { BhavikaContext } from "../Context/Bhavika";
import InputErrorMsg from "../CustomComponents/InputErrorMsg";
import { NavLink } from "react-router-dom";
import useTrans from "../hooks/useTrans";
import { avatarUrl } from "../../utils/helper";
import localCart from "../../services/cart";
import reallocalCart from "../../services/realCart";

const registerInitialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  address: "",
};
const loginInitialState = {
  email: "",
  password: "",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Header = () => {
  const searchInput = React.useRef(null);
  const history = useHistory();
  const query = useQuery();
  const q = query.get("q");
  const { lang, setLang, user, setUser } = useContext(BhavikaContext);
  const trans = useTrans();
  const currentPath = window.location.pathname;
  // console.log(currentPath);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {/* &#x25bc; */}
    </div>
  ));

  const changeLanguage = (ln) => {
    if (lang === ln) return;
    if (ln === "hk") {
      setLang("hk");
      localStorage.setItem("lang", "hk");
    } else {
      setLang("en");
      localStorage.setItem("lang", "en");
    }
  };

  // Forget Password States
  const { cart, setCart , realcart, setRealCart} = useContext(CartContext);
  const { loginShow, setLoginShow } = useContext(BhavikaContext);
  const [fpEmail, setFpEmail] = useState({
    email: "",
  });
  const [successMsg, setSuccessMsg] = useState("");
  const [warinngMsg, setWarningMsg] = useState("");
  const [spinner, showSpinner] = useState(false);

  const { isAuthenticated, setIsAuthenticated } = useContext(BhavikaContext);

  const emailChangeHandler = (event) => {
    // Object Destructuring
    const { name, value } = event.target;

    setFpEmail((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  // Forgot password Starts

  const [forgotPasswordErrMsg, setForgotPasswordErrMsg] = useState("");
  const [forgotPasswordSubmitting, setForgotPasswordSubmitting] = useState(
    false
  );

  const forgetPasswordEmailSubmitHandler = () => {
    if (!emailIsValid(fpEmail.email)) {
      setForgotPasswordErrMsg("Please enter valid email.");
      return;
    }
    setForgotPasswordSubmitting(true);
    axios({
      method: "post",
      url: `${API_BASE_URL}/api/v1/auth/forgot-password`,
      data: fpEmail,
      headers: {
        "content-type": "application/json;charset=utf-8",
      },
    })
      .then((res) => {
        // consoleLog(res.data)
        setSuccessMsg(res.data.message);
      })
      .catch((err) => {
        // consoleLog(err)
        // setWarningMsg("Account doesn't exists. Please register.");
        setWarningMsg(
          err.message ? err.message : "Error requesting forgot password."
        );
      })
      .finally(() => {
        setForgotPasswordSubmitting(false);
      });
  };

  const successPasswordChanegHandler = () => {
    setForgotPassShow(false);
    setFpEmail({ email: "" });
    setSuccessMsg("");
    setWarningMsg("");
    history.push("/");
  };
  // Forget Password States Ends

  const [search, setSearchValue] = useState(q || "");
  const [searchErrorMessage, setSearchErrorMessage] = useState("");
  const [searchContent, setSearchContent] = useState([]);
  const clearSearchBar = () => {
    const contentBox = document.getElementById("search-content-display");
    const bottomRadiusLeft = document.getElementById("basic-addon1");
    const bottomRadiusRight = document.getElementById("basic-addon2");
    contentBox.style.display = "none";
    bottomRadiusLeft.style.borderBottomLeftRadius = "40%";
    bottomRadiusRight.style.borderBottomRightRadius = "50%";
    setSearchValue("");
    setSearchContent([]);
    history.push("/");
  };

  // const [loginShow, setLoginShow] = useState(false);
  const [signUpShow, setSignUpShow] = useState(false);
  const [forgotPassShow, setForgotPassShow] = useState(false);

  const loginCreateAccountBtnHandler = () => {
    setLoginShow(false);
    setLoginErrMsg("");
    setSignUpShow(true);
    setLoginFieldEmptyError({
      email: "",
      validEmail: "",
      password: "",
      validPassword: "",
    });
  };

  const forgetPasswordLinkHandler = () => {
    setLoginShow(false);
    setSignUpShow(false);
    setForgotPasswordErrMsg("");
    setFpEmail({ email: "" });
    setForgotPassShow(true);
  };

  const loginPageRedirectHandler = () => {
    setSignUpFlag(false);
    SetSuccessState("");
    setRegisterErrMsg("");
    setSignUpFlag(false);
    setSignUpShow(false);
    setLoginShow(true);
    setSignUpFieldEmptyError({
      name: "",
      phone: "",
      address: "",
      email: "",
      validEmail: "",
      password: "",
      validPassword: "",
    });
  };

  const showSearchContent = async (e) => {
    e.preventDefault();
    // setSearchErrorMessage("");
    setSearchValue(e.target.value);
    // const contentBox = document.getElementById("search-content-display");
    // const bottomRadiusLeft = document.getElementById("basic-addon1");
    // const bottomRadiusRight = document.getElementById("basic-addon2");
    // if (e.target.value === "") {
    //   contentBox.style.display = "none";
    //   bottomRadiusLeft.style.borderBottomLeftRadius = "40%";
    //   bottomRadiusRight.style.borderBottomRightRadius = "50%";
    //   setSearchContent([]);
    // } else {
    //   contentBox.style.display = "block";
    //   bottomRadiusLeft.style.borderBottomLeftRadius = "0";
    //   bottomRadiusRight.style.borderBottomRightRadius = "0";
    // }
    // if (e.target.value.length >= 3) {
    //   showSpinner(true);
    //   await api
    //     .get(`/api/v1/search?q=${e.target.value}`)
    //     .then((response) => {
    //       if (response.data.length === 0) {
    //         setSearchErrorMessage("No data found");
    //       } else {
    //         setSearchErrorMessage(null);
    //         // for (let i = 0; i < response.data.length; i++) {
    //         //   searchContent.push(response.data[i]);
    //         // }
    //         const products = Array.isArray(response.data)
    //           ? response.data.filter((product) => product.enabled)
    //           : [];
    //         setSearchContent(products);
    //       }
    //       showSpinner(false);
    //     })
    //     .catch((error) => {
    //       setSearchContent([]);
    //       setSearchErrorMessage(error.message);
    //       showSpinner(false);
    //     });
    // }
  };

  useEffect(() => {
    const contentBox = document.getElementById("search-content-display");
    const bottomRadiusLeft = document.getElementById("basic-addon1");
    const bottomRadiusRight = document.getElementById("basic-addon2");
    const searchFunc = async () => {
      if (search.length >= 3) {
        if (document.activeElement === searchInput.current) {
          contentBox.style.display = "block";
          bottomRadiusLeft.style.borderBottomLeftRadius = "0";
          bottomRadiusRight.style.borderBottomRightRadius = "0";
        }
        try {
          showSpinner(true);
          const res = await api.get(`/api/v1/search?q=${search}`);
          if (res && res.ok) {
            setSearchContent(res.data);
          }
        } catch (error) {
          setSearchContent([]);
          console.error(error);
          setSearchErrorMessage("Search Error.");
        } finally {
          showSpinner(false);
        }
      } else {
        contentBox.style.display = "none";
        bottomRadiusLeft.style.borderBottomLeftRadius = "40%";
        bottomRadiusRight.style.borderBottomRightRadius = "50%";
        setSearchContent([]);
      }
    };
    searchFunc();
  }, [search]);

  // LOGIN STATE AND FORM HANDLE IN THIS BLOCK OF CODE

  const [loginState, SetLoginState] = useState(loginInitialState);
  const [loginErrMsg, setLoginErrMsg] = useState("");
  const [loginSubmitting, setLoginSubmitting] = useState(false);
  // const [loginSuccessState, setLoginSuccessState] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      setLoginShow(false);
      setSignUpShow(false);
      if (localStorage.getItem("userData")) {
        let userData = JSON.parse(localStorage.getItem("userData"));
        if (userData.cart && Array.isArray(userData.cart) || userData.realCart && Array.isArray(userData.realCart) )
          setCart(userData.cart);
          setRealCart(userData.realCart);
      }
    } else {
      setCart([]);
      setRealCart([]);
    }
  }, [isAuthenticated, setCart, setLoginShow, setRealCart]);

  useEffect(() => {
    if (!loginShow) {
      setLoginErrMsg("");
      SetLoginState({ ...loginInitialState });
    }
  }, [loginShow]);
  useEffect(() => {
    if (!signUpShow) {
      SetSignUpState("");
      setSuccessMsg("");
      setRegisterErrMsg("");
      SetSignUpState({ ...registerInitialState });
    }
  }, [signUpShow]);

  const loginHandler = (event) => {
    // Object Destructuring
    const { name, value } = event.target;

    SetLoginState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const logOutHandler = () => {
    api
      .get(`/api/v1/logout`)
      .then((res) => {
        consoleLog(res);
        setIsAuthenticated(false);
      })
      .catch((err) => {
        consoleError(err);
      });
  };

  const [loginFieldEmptyError, setLoginFieldEmptyError] = useState({
    email: "",
    validEmail: "",
    password: "",
    validPassword: "",
  });
  const loginFormSubmit = async (event) => {
    event.preventDefault();
    setLoginErrMsg("");
    setLoginFieldEmptyError({
      email: "",
      validEmail: "",
      password: "",
      validPassword: "",
    });
    const { email, password } = loginState;
    if (!email) {
      // setLoginErrMsg("Email is required");
      setLoginFieldEmptyError({ email: "Email is required" });
      return;
    }
    if (!emailIsValid(email)) {
      // setLoginErrMsg("Please enter valid email.");
      setLoginFieldEmptyError({ validEmail: "Please enter valid email." });
      return;
    }
    if (!password) {
      // setLoginErrMsg("Password is required");
      setLoginFieldEmptyError({ password: "Password is required" });
      return;
    }
    if (password && password.length < 8) {
      // setLoginErrMsg("Password is minimum 8 chars.");
      setLoginFieldEmptyError({
        validPassword: "Password is minimum 8 chars.",
      });
      return;
    }
    setLoginSubmitting(true);
    try {
      const res = await api.post(`/api/v1/auth/login`, loginState);
      if (res && res.ok) {
        localStorage.setItem("accessToken", res.accessToken);
        localStorage.setItem("userData", JSON.stringify(res.user));
        setUser(res.user);
        setIsAuthenticated(true);
        if (localCart.getCart().length > 0) {
          const res = await api.post("/api/v1/user/cart/localcart", {
            cart: localCart.getCart(),
          });
          if (res && res.ok) {
            localCart.emptyCart();
            setCart(res.data);
          }
        }
        if (reallocalCart.getCart().length > 0) {
          const res = await api.post("/api/v1/user/realcart/localcart", {
            cart: reallocalCart.getCart(),
          });
          if (res && res.ok) {
            reallocalCart.emptyCart();
            setRealCart(res.data);
          }
        }
        setLoginSubmitting(false);
        setLoginShow(false);
        SetLoginState({ ...loginInitialState });
      }
    } catch (err) {
      if (
        err &&
        err.errors &&
        Array.isArray(err.errors) &&
        err.errors.length > 0
      ) {
        setLoginSubmitting(false);
        setLoginErrMsg(err.errors[0].message);
      } else if (err && err.message) {
        setLoginSubmitting(false);
        setLoginErrMsg(err.message);
      } else {
        setLoginSubmitting(false);
        setLoginErrMsg("Login Error.");
      }
    }
  };
  // LOGIN STATE AND FORM HANDLE IN THIS BLOCK OF CODE ENDS HERE

  // SIGNUP STATE AND FORM HANDLE IN THIS BLOCK OF CODE
  const [signUpState, SetSignUpState] = useState(registerInitialState);
  const [registerErrMsg, setRegisterErrMsg] = useState("");
  const [successState, SetSuccessState] = useState("");
  const [registerSubmitting, setRegisterSubmitting] = useState(false);
  const [signUpFlag, setSignUpFlag] = useState(false);

  const signUpStateHandler = (event) => {
    // Object Destructuring
    const { name, value } = event.target;

    SetSignUpState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const [signUpFieldEmptyError, setSignUpFieldEmptyError] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    validEmail: "",
    validPassword: "",
  });
  const signUpFormSubmit = (event) => {
    event.preventDefault();
    setRegisterErrMsg("");
    setSignUpFieldEmptyError({
      name: "",
      phone: "",
      address: "",
      email: "",
      validEmail: "",
      password: "",
      validPassword: "",
    });
    const { name, phone, email, password } = signUpState;
    if (!name) {
      // setRegisterErrMsg("Name is required");
      setSignUpFieldEmptyError({ name: "Name is required" });
      return;
    }
    if (!phone) {
      // setRegisterErrMsg("Phone is required");
      setSignUpFieldEmptyError({ phone: "Mobile number is required" });
      return;
    } else {
      if (!/^\d{8}$/.test(phone)) {
        setSignUpFieldEmptyError({ phone: "Mobile number is 8 digits." });
        return;
      }
    }
    // if (!address) {
    //   // setRegisterErrMsg("Address is required");
    //   setSignUpFieldEmptyError({ address: "Address is required" });
    //   return;
    // }

    if (!emailIsValid(email)) {
      // setRegisterErrMsg("Please enter valid email.");
      setSignUpFieldEmptyError({ validEmail: "Please enter valid email." });
      return;
    }

    if (!email) {
      // setRegisterErrMsg("Email is required");
      setSignUpFieldEmptyError({ email: "Email is required" });
      return;
    }

    if (password && password.length < 8) {
      // setRegisterErrMsg("Password is minimum 8 chars.");
      setSignUpFieldEmptyError({
        validPassword: "Password is minimum 8 chars.",
      });
      return;
    }

    if (!password) {
      // setRegisterErrMsg("Password is required");
      setSignUpFieldEmptyError({ password: "Password is required" });
      return;
    }

    setRegisterSubmitting(true);
    api
      .post(`${API_BASE_URL}/api/v1/auth/register`, signUpState)
      .then((res) => {
        if (res.ok) {
          setRegisterSubmitting(false);
          SetSuccessState(res.message);
          SetSignUpState({ ...registerInitialState });
          setSignUpFlag(true);
        }
      })
      .catch((err) => {
        // consoleError(err);
        if (
          err &&
          err.errors &&
          Array.isArray(err.errors) &&
          err.errors.length > 0
        ) {
          setRegisterSubmitting(false);
          setRegisterErrMsg(err.errors[0].message);
        } else if (err && err.message) {
          setRegisterSubmitting(false);
          setRegisterErrMsg(err.message);
        } else {
          setRegisterSubmitting(false);
          setRegisterErrMsg("Register Error.");
        }
      });
  };
  // SIGNUP STATE AND FORM HANDLE IN THIS BLOCK OF CODE ENDS HERE

  const signUpModalOnHide = () => {
    setSignUpShow(false);
    setSignUpFieldEmptyError({
      name: "",
      phone: "",
      address: "",
      email: "",
      validEmail: "",
      password: "",
      validPassword: "",
    });
  };
  const loginModalOnHide = () => {
    setLoginShow(false);
    setLoginFieldEmptyError({
      email: "",
      validEmail: "",
      password: "",
      validPassword: "",
    });
  };

  const forgotModalOnHide = () => {
    setForgotPassShow(false);
    setFpEmail({ email: "" });
  };

  // const [topHeaderClassState,setTopHeaderClassState]=useState('top-header');

  // function scrollFunctionHeader() {
  //   if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
  //     // mybutton.style.display = "block";
  //     setTopHeaderClassState('top-headerOnScroll');
  //    }else{
  //     setTopHeaderClassState('top-header');
  //    }
  // }

  // window.addEventListener('scroll', scrollFunctionHeader);

  return (
    <>
      {/* top-headerOnScroll */}
      <div className={`row top-header sticky-top`}>
        {/* <div id="top" name="top"></div> */}
        <BackToTop />
        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
          {/* <a href="/"> */}
          <NavLink to="/">
            <img src={logo} alt="logo" className={"img-fluid logo"} />
          </NavLink>
          {/* </a> */}
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-12 p-0">
          <div className="input-group search-bar">
            <div className="input-group-prepend search-icon-box">
              <span className="input-group-text bg-white" id="basic-addon1">
                <i className="fas fa-search" />
              </span>
            </div>
            <input
              type="text"
              name="search"
              value={search}
              onChange={(e) => showSearchContent(e)}
              placeholder={trans.whatToBuy}
              className="form-control input-search-box"
              autoComplete={"off"}
              ref={searchInput}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  history.push(`/search?q=${search}`);
                }
                const contentBox = document.getElementById(
                  "search-content-display"
                );
                contentBox.style.display = "none";
              }}
              onBlur={() => {
                const contentBox = document.getElementById(
                  "search-content-display"
                );
                const bottomRadiusLeft = document.getElementById(
                  "basic-addon1"
                );
                const bottomRadiusRight = document.getElementById(
                  "basic-addon2"
                );
                contentBox.style.display = "none";
                bottomRadiusLeft.style.borderBottomLeftRadius = "40%";
                bottomRadiusRight.style.borderBottomRightRadius = "50%";
              }}
              onFocus={() => {
                if (search) {
                  const contentBox = document.getElementById(
                    "search-content-display"
                  );
                  const bottomRadiusLeft = document.getElementById(
                    "basic-addon1"
                  );
                  const bottomRadiusRight = document.getElementById(
                    "basic-addon2"
                  );
                  contentBox.style.display = "initial";
                  bottomRadiusLeft.style.borderBottomLeftRadius = "0";
                  bottomRadiusRight.style.borderBottomRightRadius = "0";
                }
              }}
            />
            <div className="input-group-append clear-icon-box">
              <span
                onClick={() => {
                  if (spinner) {
                    return;
                  } else {
                    clearSearchBar();
                  }
                }}
                className="input-group-text bg-white"
                id="basic-addon2"
              >
                {spinner ? (
                  <Spinner animation="border" size="sm" variant="primary" />
                ) : (
                  <i className="fa fa-times" />
                )}
              </span>
            </div>
            <div
              className="container bg-white search-bar-content"
              id={"search-content-display"}
            >
              <ul>
                {searchContent.map((product, index) => {
                  return (
                    <li
                      key={index}
                      className="productListItem"
                      onMouseDown={(e) => {
                        e.preventDefault();
                        history.push(`/products/${product.slug}`);
                        const contentBox = document.getElementById(
                          "search-content-display"
                        );
                        contentBox.style.display = "none";
                      }}
                    >
                      <div className="productRow">
                        <div>{product.name}</div>
                      </div>
                      {/* <a
                        href={`/products/${product.slug}`}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          history.push(`/search?q=${search}`);
                          const contentBox = document.getElementById(
                            "search-content-display"
                          );
                          contentBox.style.display = "none";
                        }}
                      >
                        {product.name}
                      </a> */}
                    </li>
                  );
                })}
                <li
                  className="productListItem"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    history.push(`/search?q=${search}`);
                    const contentBox = document.getElementById(
                      "search-content-display"
                    );
                    contentBox.style.display = "none";
                  }}
                >
                  <div className="searchAll">
                    <div className="searchIcon">
                      <i className="fas fa-search" />
                    </div>
                    <div className="searchString">
                      Search all for <strong>"{search}"</strong>
                    </div>
                  </div>
                </li>
              </ul>
              <div className={"text-center search-bar-message"}>
                {searchErrorMessage ? searchErrorMessage : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-6 col-12 p-0 loginRightDivMainDiv">
          <div className="icons-shortcut float-right  loginAndCartMainDiv mr-3">
            {/* col-lg-12 col-md-8 col-5 */}
            <span
              className="store-locator"
              onClick={() => history.push("/bhavika-restaurant")}
            >
              {/* <i className="fas fa-map-marker-alt" /> */}
              <img
              src={cutleryImg}
              className="img-fluid cutlery-icon"
              />
              <span>{trans.bhavikaRestaurant}</span>
            </span>

            <span
              className="store-locator"
              onClick={() => history.push("/location")}
            >
              <i className="fas fa-map-marker-alt" />
              <span>{trans.storeLocator}</span>
            </span>

            {isAuthenticated ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="/#"
                  data-toggle="dropdown"
                  style={{ color: "white" }}
                >
                  <img
                    src={avatarUrl(user?.avatar)}
                    alt="Avatar"
                    className="img-fluid dropbtn navbar-avatar"
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    {getFirstName(user?.name)}
                  </span>
                </a>
                <ul className="dropdown-menu" style={{ zIndex: "1024" }}>
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      {" "}
                      {trans.profile}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/wishlist">
                      {" "}
                      {trans.wishlist}
                    </Link>
                  </li>
                  <li onClick={() => logOutHandler()} className="dropdown-item">
                    {/* <a href="/#" className="dropdown-item"> */}
                    {/* {" "} */}
                    {trans.logout}
                    {/* </a> */}
                  </li>
                </ul>
              </li>
            ) : (
              <span
                className="user-login"
                onClick={() => setLoginShow(true)}
                style={{ cursor: "pointer" }}
              >
                {trans.login}
              </span>
            )}
            {
              currentPath === '/real-taste-of-india' || currentPath === '/carts' || currentPath === '/checkouts'
              ?
              <Link to="/carts">
              <span className="cart-logo  p-0">
                <i className="fa fa-shopping-cart" />
                <sup>
                  <Badge pill variant="info">
                    {realcart.reduce((acc, item) => (acc = acc + item.quantity), 0)}
                  </Badge>
                </sup>
              </span>
            </Link>
              :
              <Link to="/cart">
              <span className="cart-logo  p-0">
                <i className="fa fa-shopping-cart" />
                <sup>
                  <Badge pill variant="info">
                    {cart.reduce((acc, item) => (acc = acc + item.quantity), 0)}
                  </Badge>
                </sup>
              </span>
            </Link>
            }
            {/* {isAuthenticated ? (
            ) : (
              <a
                href={"/#"}
                onClick={(e) => {
                  e.preventDefault();
                  setLoginShow(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <span className="cart-logo  p-0">
                  <i className="fa fa-shopping-cart" />
                </span>
              </a>
            )} */}

            {/* <span id="language-selector"> */}
            <span className="languageSelectorDiv">
              <Dropdown id="lang-dropdown">
                <Dropdown.Toggle as={CustomToggle}>
                  <i className="fa fa-globe fa-lg ml-1" />
                  <sub>
                    <Badge pill variant="info">
                      {lang === "hk" ? "简体" : "EN"}
                    </Badge>
                  </sub>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    EN
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("hk")}>
                    简体
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        </div>
      </div>

      {/* ================================ SIGN UP AND LOGIN MODULE STARTS FROM  HERE ============================================== */}

      {/* LOGIN Model STARTS HERE*/}
      <Modal
        size="lg"
        show={loginShow}
        // setLoginShow(false)
        onHide={loginModalOnHide}
        aria-labelledby="example-modal-sizes-title-lg"
        // animation={false}
        centered
      >
        {/* <Modal.Header closeButton>
   <Modal.Title id="example-modal-sizes-title-lg">
     Large Modal
   </Modal.Title>
 </Modal.Header> */}

        <Modal.Body className="loginModalInnerDiv ">
          <div className="modalLeftDiv">
            <img
              src={loginImg}
              alt="login modal banner"
              className="img-fluid"
            />
          </div>
          <div className="modalRightDiv">
            <div className="d-flex w-100  justify-content-end">
              <div className="w-10 closeButton" onClick={loginModalOnHide}>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <div>X</div>
              </div>
            </div>
            <div className="loginWithSocialMediaDiv">
              <div className="loginTitle">
                <span style={{ color: "yellowgreen", fontSize: "30px" }}>
                  {trans.login}
                </span>
                <span style={{ fontSize: "30px" }}>
                  &nbsp;{trans.yourAccount}
                </span>
              </div>
              {/* <div className="socialMediaButton bg-danger">
                <FacebookLoginButton
                  setIsAuthenticated={setIsAuthenticated}
                  setErrorMessage={SetLoginErrState}
                />
                <GoogleLoginButton
                  setIsAuthenticated={setIsAuthenticated}
                  setErrorMessage={SetLoginErrState}
                />
              </div>
              <span style={{ fontWeight: "700", fontSize: "20px" }}>OR</span> */}
            </div>
            {/* {loginErrState ? (
              <span style={{ color: "red", textAlign: "center" }}>
                {loginErrState}
              </span>
            ) : (
              ""
            )} */}
            {loginErrMsg ? <FailedMessage msg={loginErrMsg} /> : ""}
            <form name="login" className="inputFieldForLogin">
              <div className="inputGroup form-group">
                <label htmlFor="email">
                  {trans.emailAddress} <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  value={loginState.email}
                  onChange={loginHandler}
                  placeholder={trans.enterEmail}
                  autoComplete="username"
                  className="form-control"
                  maxLength={50}
                />
                {loginFieldEmptyError.email ||
                loginFieldEmptyError.validEmail ? (
                  <InputErrorMsg>
                    {loginFieldEmptyError.email}
                    {loginFieldEmptyError.validEmail}
                  </InputErrorMsg>
                ) : (
                  ""
                )}
              </div>
              <div className="inputGroup form-group">
                <label htmlFor="password">
                  {trans.password} <span style={{ color: "red" }}>*</span>
                </label>

                <input
                  type="password"
                  name="password"
                  value={loginState.password}
                  onChange={loginHandler}
                  placeholder={trans.enterPassword}
                  autoComplete="current-password"
                  className="form-control"
                  maxLength={50}
                />
                {loginFieldEmptyError.password ||
                loginFieldEmptyError.validPassword ? (
                  <InputErrorMsg>
                    {loginFieldEmptyError.password}
                    {loginFieldEmptyError.validPassword}
                  </InputErrorMsg>
                ) : (
                  ""
                )}
              </div>
              {/* {loginErrMsg ? (
                <span
                  style={{
                    color: "crimson",
                    textAlign: "center",
                  }}
                >
                  {loginErrMsg}
                </span>
              ) : (
                ""
              )} */}
              <div onClick={loginFormSubmit} className="mt-2">
                <CustomButtons
                  color="yellowgreen"
                  width="150px"
                  height="40px"
                  text={trans.login}
                  type="submit"
                  submitting={loginSubmitting}
                />
              </div>
            </form>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mb-2">
              <span style={{ fontWeight: "700", fontSize: "20px" }}>OR</span>
              <div className="socialMediaButton pr-2 pl-2">
                <FacebookLoginButton setErrorMessage={setLoginErrMsg} />
                <GoogleLoginButton setErrorMessage={setLoginErrMsg} />
              </div>
            </div>
            <span
              style={{ color: "green", cursor: "pointer" }}
              // data-toggle="modal"
              // data-target="#forget-password-modal"
              onClick={forgetPasswordLinkHandler}
            >
              {trans.forgotPassword}
            </span>
            <span
              style={{ color: "green", marginTop: "20px", cursor: "pointer" }}
              onClick={loginCreateAccountBtnHandler}
            >
              {trans.createAccount}
            </span>
          </div>
        </Modal.Body>
      </Modal>
      {/* LOGIN Model ENDS HERE*/}

      {/* SignUp Model STARTS HERE*/}
      <Modal
        size="lg"
        show={signUpShow}
        // setSignUpShow(false)
        onHide={signUpModalOnHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="border-radius-2"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="loginModalInnerDiv " style={{ height: "700px" }}>
          <div className="modalRightDiv">
            {/* <div className="d-flex w-100  justify-content-end">
              <div
                className="w-10 closeButtonSignUp"
                onClick={() => setSignUpShow(false)}
              >
                <div>X</div>
              </div>
            </div> */}
            <div className="loginWithSocialMediaDiv">
              <div className="loginTitle">
                <span style={{ color: "yellowgreen", fontSize: "30px" }}>
                  {trans.signUp}
                </span>
                <span style={{ fontSize: "30px" }}>
                  &nbsp;{trans.toBhavika}
                </span>
              </div>
              {/* <div className="socialMediaButton">
                <FacebookLoginButton
                  setIsAuthenticated={setIsAuthenticated}
                  setErrorMessage={SetErrState}
                />
                <GoogleLoginButton
                  setIsAuthenticated={setIsAuthenticated}
                  setErrorMessage={SetErrState}
                />
              </div>
              <span style={{ fontWeight: "700", fontSize: "20px" }}>OR</span> */}
            </div>

            {successState ? (
              <div className="container">
                {/* <span
                  style={{ color: "green", textAlign: "center", width: "80%" }}
                >
                  {successState}
                </span> */}
                <SuccessMessage msg={successState} />
              </div>
            ) : (
              ""
            )}
            {registerErrMsg ? <FailedMessage msg={registerErrMsg} /> : ""}

            {/* {errState ? (
              <span style={{ color: "red", textAlign: "center" }}>
                {errState}
              </span>
            ) : (
              ""
            )} */}

            {signUpFlag ? (
              ""
            ) : (
              <form name="register" className="inputFieldForLogin">
                <div className="inputGroup form-group">
                  <label htmlFor="name">
                    {trans.name} <span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    id="name"
                    name="name"
                    value={signUpState.name}
                    onChange={signUpStateHandler}
                    placeholder={trans.enterName}
                    autoComplete="name"
                    className=" form-control"
                    maxLength={50}
                  />
                  {signUpFieldEmptyError.name ? (
                    <InputErrorMsg>{signUpFieldEmptyError.name}</InputErrorMsg>
                  ) : (
                    ""
                  )}
                </div>
                <div className="inputGroup form-group">
                  <label htmlFor="phone">
                    {trans.mobileNumber} <span style={{ color: "red" }}>*</span>
                  </label>

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        +852
                      </span>
                    </div>
                    <input
                      id="phone"
                      name="phone"
                      value={signUpState.phone}
                      onChange={signUpStateHandler}
                      placeholder={trans.enterMobile}
                      autoComplete="phone"
                      className=" form-control"
                      type="tel"
                      maxLength={8}
                    />
                  </div>
                  {signUpFieldEmptyError.phone ? (
                    <InputErrorMsg>{signUpFieldEmptyError.phone}</InputErrorMsg>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="inputGroup form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    id="address"
                    name="address"
                    value={signUpState.address}
                    onChange={signUpStateHandler}
                    placeholder="Enter address"
                    autoComplete="off"
                    type="text"
                    className=" form-control"
                    maxLength={200}
                  />
                  {signUpFieldEmptyError.address ? (
                    <InputErrorMsg>
                      {signUpFieldEmptyError.address}
                    </InputErrorMsg>
                  ) : (
                    ""
                  )}
                </div> */}
                <div className="inputGroup form-group">
                  <label htmlFor="email">
                    {trans.emailAddress} <span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    id="email"
                    name="email"
                    value={signUpState.email}
                    onChange={signUpStateHandler}
                    placeholder={trans.enterEmail}
                    autoComplete="email"
                    className=" form-control"
                    maxLength={50}
                  />
                  {signUpFieldEmptyError.email ||
                  signUpFieldEmptyError.validEmail ? (
                    <InputErrorMsg>
                      {signUpFieldEmptyError.name}
                      {signUpFieldEmptyError.validEmail}
                    </InputErrorMsg>
                  ) : (
                    ""
                  )}
                </div>
                <div className="inputGroup form-group">
                  <label htmlFor="password">
                    {trans.password} <span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={signUpState.password}
                    onChange={signUpStateHandler}
                    placeholder={trans.enterPassword}
                    autoComplete="new-password"
                    className=" form-control"
                    maxLength={50}
                  />
                  {signUpFieldEmptyError.password ||
                  signUpFieldEmptyError.validPassword ? (
                    <InputErrorMsg>
                      {signUpFieldEmptyError.name}
                      {signUpFieldEmptyError.validPassword}
                    </InputErrorMsg>
                  ) : (
                    ""
                  )}
                </div>

                {/* {registerErrMsg ? (
                  <span
                    style={{
                      color: "crimson",
                      textAlign: "center",
                    }}
                  >
                    {registerErrMsg}
                  </span>
                ) : (
                  ""
                )} */}
                <div onClick={signUpFormSubmit} className="mt-2">
                  <CustomButtons
                    color="yellowgreen"
                    width="150px"
                    height="40px"
                    text={trans.register}
                    submitting={registerSubmitting}
                  />
                </div>
              </form>
            )}
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mb-2">
              <span style={{ fontWeight: "700", fontSize: "20px" }}>OR</span>
              <div className="socialMediaButton  pr-2 pl-2">
                <FacebookLoginButton setErrorMessage={setRegisterErrMsg} />
                <GoogleLoginButton setErrorMessage={setRegisterErrMsg} />
              </div>
            </div>

            <span
              style={{ color: "green", marginTop: "-10px", cursor: "pointer" }}
              // onClick={() => setLoginShow(true)}
              onClick={loginPageRedirectHandler}
            >
              {/* Login */}
              <i className="far fa-arrow-alt-circle-left"></i>{" "}
              {trans.backToLogin}
            </span>
          </div>
          <div className="modalLeftDiv">
            {/* <div className="d-flex w-100  justify-content-end">
              <div className="w-10 closeButton">
                <div onClick={() => setSignUpShow(false)}>X</div>
              </div>
            </div> */}
            <img
              src={loginImg}
              alt="login modal banner"
              className="img-fluid"
            />
            <div className="d-flex w-100  justify-content-end">
              <div
                className="w-10 closeButtonSignUp"
                onClick={signUpModalOnHide}
              >
                <div>X</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* SignUp Model ENDS HERE*/}

      {/* Forget Password Modal */}
      <Modal
        size="md"
        show={forgotPassShow}
        onHide={() => setForgotPassShow(false)}
        aria-labelledby="forgot-pass"
        className="border-radius-2"
        centered
      >
        <Modal.Header>
          <Modal.Title id="forgot-pass">
            <div className="font-weight-bold">
              <span style={{ fontSize: "20px" }}>Forget Your Password ?</span>
            </div>
          </Modal.Title>
          <div className="d-flex w-auto  justify-content-end">
            <div
              className="w-10 forgotPassowrdcloseButton"
              onClick={forgotModalOnHide}
            >
              <div>X</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {successMsg ? (
            <>
              <SuccessMessage msg={successMsg} />
              <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  onClick={successPasswordChanegHandler}
                  className="btn"
                  style={{ backgroundColor: "yellowgreen", color: "white" }}
                >
                  Ok
                </button>
              </div>
            </>
          ) : warinngMsg ? (
            <>
              <FailedMessage msg={warinngMsg} />
              <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  onClick={successPasswordChanegHandler}
                  className="btn"
                  style={{ backgroundColor: "yellowgreen", color: "white" }}
                >
                  Ok
                </button>
              </div>
            </>
          ) : (
            <div className="container-fluid">
              <div className="row">
                {/* <div className=" col-lg-12 col-md-12 col-12  d-flex justify-content-center align-items-center font-weight-bold mt-3 mb-3">
                      <span style={{ fontSize: "20px" }}>
                        Forget Your Password ?
                      </span>
                    </div> */}
                <div className=" col-lg-12 col-md-12 col-12  d-flex justify-content-center align-items-center mb-2">
                  <span style={{ fontSize: "17px" }}>
                    Don't worry just enter your email address and we'll set you
                    up with a new password in no time.
                  </span>
                </div>

                <div className=" col-lg-12 col-md-12 col-12  d-flex justify-content-center align-items-center  mb-2">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={fpEmail.email}
                    onChange={emailChangeHandler}
                    // style={{ outline: "none" }}
                    className="forgetPasswordEmailField form-control"
                    maxLength={50}
                  />
                </div>
                {forgotPasswordErrMsg ? (
                  <div
                    className="text-center m-auto"
                    style={{
                      color: "crimson",
                    }}
                  >
                    {forgotPasswordErrMsg}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center mt-2">
                  <button
                    type="submit"
                    onClick={forgetPasswordEmailSubmitHandler}
                    className="forgetPasswordButton"
                    disabled={forgotPasswordSubmitting}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div>Send Link</div>
                      {forgotPasswordSubmitting ? (
                        <div>
                          <Spinner animation="grow" />
                        </div>
                      ) : null}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* Forget Password Modal Ends */}
      {/* ===================SIGN UP AND LOGIN MODULE ENDS HERE ================================ */}
    </>
  );
};

export default Header;
