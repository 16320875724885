import React,{useState,useEffect, useContext} from "react";
import useTrans from "../hooks/useTrans";
import banner from "../../assets/realTasteOfIndia/banner.jpg";

import "../../scss/include/_realTasteOfIndiaMenu.scss";
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../services/api";
import { consoleError, productUrl} from "../../utils/helper";
import reallocalCart from "../../services/realCart";
import { CartContext } from "../Context/Cart";
import {
  Button,
  Modal,
} from "react-bootstrap";
import useTransPara from "../hooks/useTransPara";
import { useHistory } from "react-router";



const RealTasteOfIndiaMenu = () => {
  // const transName = useTransPara("name");
  const trans = useTrans();
  const history = useHistory();


  const [category,setCategory]=useState(null);
  // const [donthaveProduct, setDontHaveProduct] = useState(true);
  const { setRealCart } = useContext(CartContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [addedQuantity, setAddedQuantity] = useState(0);
  const [addedProduct,setAddedProduct]=useState();





  useEffect(()=>{
    getCategoryDetails();
  },[])

const getCategoryDetails = async () =>{
  try {
    const res = await api.get(`/api/v1/category/slug/real-taste-of-india`);
    if (res && res.data) {
      // console.log(res.data)
      setCategory(res.data);
      // console.log(`Product is : ${res.data.products}`)
      // if (res.data.subcategory.length >= 1) {
      //   setDontHaveProduct(false);
      // }
    }
    // setPageLoading(false);
  } catch (error) {
    consoleError(error);

    // setPageLoading(false);
  }
}

const openProductSuccessModal = (quantity) => {
  setAddedQuantity(quantity);
  setShowSuccessModal(true);
};

const closeProductSuccessModal = () => {
  setAddedQuantity(0);
  setShowSuccessModal(false);
};

const addToCartButton = async (product) => {
  // alert(
  //   `Product Image Name: ${product.banner}, Product Name is: ${product.name}, Price is: ${product.price}, Kgs is: ${product.unit}, Quantity is: ${productQuantity}`
  // );
  if (localStorage.userData) {
    try {
      let res = await api.post("/api/v1/user/realcart/add", {
        product: product._id,
        quantity: 1,
      });
      if (res && res.ok) {
        console.log(res);
        setRealCart(res.data);
        openProductSuccessModal(1);
        setAddedProduct(product);
      }
    } catch (error) {
      consoleError(error);
    }
  } else {
    // setShowLoginMsg(true);
    reallocalCart.addProduct(product, 1);
    setRealCart(reallocalCart.getCart());
    openProductSuccessModal(1);
    setAddedProduct(product);
  }
};

  return (
    <>
    <div className="real-taste-of-india-wrapper">
      <img src={category ? productUrl(category.banner) : banner} alt="banner" className="banner-image" />
      <div className="side-by-side title">
        <p>Menu</p>
        {/* <a href="#">
          Expand All
          <FontAwesomeIcon icon={faArrowsAltV} />
        </a> */}
      </div>
      <div className="menu-items">
        <Accordion style={{cursor:'pointer'}}>
          {
            category
            ?
            category.subcategory.length > 0
            ?
            category.subcategory.map((data)=>(
              <Card key={data._id}>
              <Accordion.Toggle as={Card.Header} eventKey={data._id}>
                <div className="menu-item side-by-side">
                  <h2>{data.name}</h2>
                  {
                    data.banner
                    ?
                    <img src={productUrl(data.banner)}alt={"item"} />
                    :
                    ""
                  }
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={data._id}>
                <Card.Body>
                  {
                    data.products.map((product)=>(
                    <ul key={product._id}>
                    <li className="side-by-side"  onClick={()=>addToCartButton(product)} >
                      <span className="item-title">
                        <a >{product.name}</a>
                      </span>
                      <span className="item-price">HK$ {product.price}</span>
                      <span className="cart-icon">
                        <a>
                          <FontAwesomeIcon icon={faShoppingCart}/>
                        </a>
                      </span>
                    </li>
                  </ul>
                    ))
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            ))
            :
            <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <div className="text-center text-muted mb-2">
            <h4>No Items</h4>
          </div>
        </div>
        :
        ""
          }
        </Accordion>
      </div>
    </div>
    {
      addedProduct
      ?
      <Modal
         size="lg"
         show={showSuccessModal}
         onHide={closeProductSuccessModal}
         aria-labelledby="example-modal-sizes-title-lg"
         className="modelMainDiv"
         centered
       >
         <Modal.Header>
           <h5>This item has been added to the cart.</h5>
           <button
             className="btn btn-outline-secondary btn-circle"
             onClick={closeProductSuccessModal}
           >
             <i className="fa fa-times"></i>
           </button>
         </Modal.Header>
         <Modal.Body>
           <div>
             <div className="row">
               <div className="col-lg-4 col-md-6 col-sm-12">
                 <img
                   src={productUrl(addedProduct.banner)}
                   className="img-fluid imgSize"
                   alt="cat-product"
                 />
               </div>
               <div className="col-lg-8 col-md-6 col-sm-12">
                 <div>
                   <span className="font-weight-bold">Product: </span>
                   {addedProduct.name}
                   {/* {transName(addedProduct.name)} */}
                 </div>
                 <div>
                   <span className="font-weight-bold">Quantity: </span>
                   {addedQuantity}
                 </div>
               </div>
             </div>
           </div>
           <div className="container mt-4 mb-3 d-flex justify-content-between flex-wrap">
             <Button
               variant="info"
               className="rounded-pill"
               onClick={closeProductSuccessModal}
             >
               {trans.continueShopping}
             </Button>
             <Button
               variant="success"
               className="rounded-pill"
               onClick={() => {
                 history.push("/carts");
               }}
             >
               {trans.proceedToCart}
             </Button>
           </div>
         </Modal.Body>
       </Modal>
      :
      ""
    }
         
       </>
  );
};
export default RealTasteOfIndiaMenu;
