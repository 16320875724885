import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import "../../scss/include/_deliveryAddress.scss";
import api from "../../services/api";
import { consoleError, consoleLog } from "../../utils/helper";
import useTrans from "../hooks/useTrans";
import FailedMessage from "./FailedMessage";
import InputErrorMsg from "./InputErrorMsg";

const DeliveryAddress = ({
  setShippingPrice,
  selectedAddress,
  setSelectedAddress,
}) => {
  const trans = useTrans();
  const [formState, setFormState] = useState({
    id: "",
    name: "",
    address: "",
    region: "",
    phone: "",
  });

  const [formSubmitErrorMsg, setFormSubmitErrorMsg] = useState({
    name: "",
    address: "",
    region: "",
    phone: "",
  });
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");

  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [deleteAddressShow, setDeleteAddressShow] = useState(false);
  const [deleteAddressOkState] = useState(true);
  const [deletingAddress, setDeletingAddress] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    async function getUserShippingAddresss() {
      try {
        const res = await api.get("/api/v1/user/shipping/address");
        if (res && res.ok) {
          setShippingAddress(res.data);
          if (res.data && Array.isArray(res.data) && res.data.length > 0) {
            setSelectedAddress(res.data[0]._id);
            setShippingPrice(res.data[0].price || 0.0);
          }
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getUserShippingAddresss();
  }, [setSelectedAddress, setShippingPrice]);

  const formChangeHandler = (event) => {
    // Object Destructuring
    const { name, value } = event.target;

    setFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const formHandler = async () => {
    setFormSubmitErrorMsg({
      name: "",
      address: "",
      region: "",
      phone: "",
    });
    const { name, address, region, phone } = formState;
    if (!name) {
      setFormSubmitErrorMsg({ name: "Name is required" });
      return;
    }
    if (!address) {
      setFormSubmitErrorMsg({ address: "Address is required" });
      return;
    }
    if (!region) {
      setFormSubmitErrorMsg({ region: "Region is required" });
      return;
    }
    if (!phone) {
      setFormSubmitErrorMsg({ region: "Mobile number is required" });
      return;
    }
    if (!/^\d{8}$/.test(phone)) {
      setFormSubmitErrorMsg({ phone: "Mobile number is 8 digits." });
      return;
    }

    if (update) {
      try {
        setSubmitting(true);
        const res = await api.patch(
          `/api/v1/user/shipping/address/${formState.id}`,
          formState
        );
        if (res && res.ok) {
          setSubmitting(false);
          setSubmitErrorMsg("");
          setShippingAddress(res.data);
          setUpdate(false);
          setShow(false);
        }
      } catch (err) {
        if (
          err &&
          err.errors &&
          Array.isArray(err.errors) &&
          err.errors.length > 0
        ) {
          setSubmitting(false);
          setSubmitErrorMsg(err.errors[0].message);
        } else if (err && err.message) {
          setSubmitting(false);
          setSubmitErrorMsg(err.message);
        } else {
          consoleError(err);
          setSubmitting(false);
          setSubmitErrorMsg("Error updating address.");
        }
      }
    } else {
      try {
        setSubmitting(true);
        const res = await api.post("/api/v1/user/shipping/address", formState);
        if (res && res.ok) {
          setSubmitting(false);
          setShippingAddress(res.data);
          setSubmitErrorMsg("");
          setShow(false);
        }
      } catch (err) {
        if (
          err &&
          err.errors &&
          Array.isArray(err.errors) &&
          err.errors.length > 0
        ) {
          setSubmitting(false);
          setSubmitErrorMsg(err.errors[0].message);
        } else if (err && err.message) {
          setSubmitting(false);
          setSubmitErrorMsg(err.message);
        } else {
          consoleError(err);
          setSubmitting(false);
          setSubmitErrorMsg("Error updating address.");
        }
      }
    }
  };

  const editShippingAddress = (address) => {
    setUpdate(true);
    setFormState({
      id: address._id,
      name: address.name,
      address: address.address,
      region: address.region,
      phone: address.phone,
    });
    setShow(true);
  };
  const deleteShippingAddress = async (address) => {
    // const deleteAddr = window.confirm(
    //   `Delete shipping address ${address.address} ?`
    // );
    // setDeleteAddressState(true);

    if (deleteAddressOkState) {
      try {
        const res = await api.delete(
          `/api/v1/user/shipping/address/${address._id}`
        );
        if (res && res.ok) {
          consoleLog("Address deleted successfuly");
          // localStorage.setItem("shippingAddress", "");
          if (selectedAddress === address._id) {
            setSelectedAddress("");
            setShippingPrice(0.0);
          }
          setShippingAddress(res.data);
          setDeleteAddressShow(false);
        }
      } catch (error) {
        consoleError(error);
      }
    } else {
      consoleLog("Delete cancelled");
    }
  };

  // SELECTED ADDRESS HANDLER STARTS
  const addressChangeHandler = (event) => {
    setSelectedAddress(event.target.value);
    const item = shippingAddress.find((item) => item._id === selectedAddress);
    if (item) {
      setShippingPrice(item.price);
    }
    localStorage.setItem("shippingAddress", event.target.value);
  };
  // consoleLog(`Selected Address is: ${selectedAddress}`);
  // SELECTED ADDRESS HANDLER ENDS

  const addAddressModalClose = () => {
    setShow(false);
    setSubmitErrorMsg("");
    setFormSubmitErrorMsg({
      name: "",
      address: "",
      region: "",
      phone: "",
    });
  };

  return (
    <>
      <div className=" delivery-address-table">
        <div className="row">
          <h4>
            {trans.confirmDeliveryAddress}
            <div className="borderBottom"></div>
          </h4>
          <button
            className="btn btn-sm rounded-pill mr-3"
            onClick={() => {
              setUpdate(false);
              setFormState({
                id: "",
                name: "",
                address: "",
                region: "",
                phone: "",
              });
              setShow(true);
            }}
            style={{
              fontSize: 15,
              marginLeft: 5,
              backgroundColor: "yellowgreen",
              color: "white",
              position: "absolute",
              right: "0",
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
            {trans.addNewAddress}
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Select</th>
                <th>Full Name</th>
                <th>Address</th>
                <th>Region</th>
                <th>Mobile Number</th>
                <th>Action</th>
                {/* <th>Selected</th> */}
              </tr>
            </thead>
            <tbody>
              {shippingAddress.map((addr, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="selectedAddress"
                        value={addr._id}
                        checked={addr._id === selectedAddress}
                        onChange={addressChangeHandler}
                      />
                    </div>
                  </td>
                  <td>{addr.name}</td>
                  <td>{addr.address}</td>
                  <td>{addr.region}</td>
                  <td>{addr.phone}</td>
                  <td>
                    <span
                      className="btn btn-warning btn-sm text-white"
                      onClick={() => {
                        editShippingAddress(addr);
                      }}
                    >
                      {/* Edit */}
                      <i className="fa fa-edit"></i>
                    </span>
                    &nbsp;
                    <span
                      className="btn btn-danger btn-sm text-white"
                      onClick={() => {
                        setDeleteAddressShow(true);
                        setDeletingAddress(addr);
                        // deleteShippingAddress(addr);
                      }}
                    >
                      {/* Delete */}
                      <i className="fa fa-trash-alt"></i>
                    </span>
                  </td>
                  {/* <td>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="selectedAddress"
                        value={addr._id}
                        checked={addr._id === selectedAddress}
                        onChange={addressChangeHandler}
                      />
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="container-fluid  ">
        <div className="row d-flex justify-content-end align-items-end">
          <div className="col-lg-3 col-md-3 col-12  d-flex justify-content-center align-items-center">
            <div className="addNewAddressCustomBtn d-flex justify-content-center align-items-center">
              {/* <span className="ml-3">Add New Address</span> */}
            </div>
          </div>
        </div>
      </div>

      {/* MODAL STARTS */}
      <Modal
        size="md"
        onHide={() => setShow(false)}
        show={show}
        aria-labelledby="address-modal"
      >
        <ModalHeader>
          <ModalTitle>
            {update ? "Update Address" : "Add New Address"}
          </ModalTitle>
        </ModalHeader>
        {submitErrorMsg ? <FailedMessage msg={submitErrorMsg} /> : ""}
        <ModalBody>
          <form>
            <div className="form-group">
              <label>{trans.name}</label>
              <input
                type="text"
                className="form-control"
                placeholder={trans.name}
                name="name"
                value={formState.name}
                onChange={formChangeHandler}
                required
                maxlength="50"
              />
              {formSubmitErrorMsg.name ? (
                <InputErrorMsg>{formSubmitErrorMsg.name}</InputErrorMsg>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{trans.address}</label>
              <input
                type="text"
                className="form-control"
                placeholder={trans.enterAddress}
                name="address"
                value={formState.address}
                onChange={formChangeHandler}
                required
                maxlength="200"
              />
              {formSubmitErrorMsg.address ? (
                <InputErrorMsg>{formSubmitErrorMsg.address}</InputErrorMsg>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>{trans.region}</label>
              <input
                type="text"
                className="form-control"
                placeholder={trans.enterRegion}
                name="region"
                value={formState.region}
                onChange={formChangeHandler}
                required
                maxlength="50"
              />
              {formSubmitErrorMsg.region ? (
                <InputErrorMsg>{formSubmitErrorMsg.region}</InputErrorMsg>
              ) : (
                ""
              )}
            </div>
            <label>{trans.mobileNumber}</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  +852
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={trans.enterMobile}
                name="phone"
                value={formState.phone}
                onChange={formChangeHandler}
                maxLength={8}
                required
              />
            </div>
            {formSubmitErrorMsg.phone ? (
              <InputErrorMsg>{formSubmitErrorMsg.phone}</InputErrorMsg>
            ) : (
              ""
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary rounded-pill"
            onClick={addAddressModalClose}
          >
            {trans.close}
          </button>
          <button
            type="button"
            className="btn rounded-pill"
            onClick={formHandler}
            disabled={submitting}
            style={{ backgroundColor: "yellowgreen", color: "white" }}
          >
            {update
              ? submitting
                ? trans.update + "..."
                : trans.update
              : submitting
              ? trans.save + "..."
              : trans.save}
          </button>
        </ModalFooter>
      </Modal>
      {/* MODAL ENDS */}

      {/* DELETE ADDRESS MODAL STARTS */}
      <Modal
        size="md"
        show={deleteAddressShow}
        onHide={() => setDeleteAddressShow(false)}
        aria-labelledby="delete-address"
        className="border-radius-2"
        centered
      >
        <ModalHeader closeButton>
          <ModalTitle>Delete Address</ModalTitle>
        </ModalHeader>

        <ModalBody>
          Are you sure you want to delete shipping address{" "}
          <span className="font-weight-bold">{deletingAddress.address}</span>?
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setDeleteAddressShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => deleteShippingAddress(deletingAddress)}
          >
            OK
          </button>
        </ModalFooter>
      </Modal>
      {/* DELETE ADDRESS MODAL ENDS */}
    </>
  );
};
export default DeliveryAddress;
