import React, { useContext, useEffect, useState } from "react";
import UserAvatarComponents from "../CustomComponents/UserAvatarComponents";
import UserDetailsComponents from "../CustomComponents/UserDetailsComponents";
// import ShopByHeading from '../CustomComponents/ShopByHeading';
import RecentOrdersComponents from "../CustomComponents/RecentOrdersComponents";
import "../../scss/include/userProfile.scss";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";
import SuccessMessage from "../CustomComponents/SuccessMessage";
import FailedMessage from "../CustomComponents/FailedMessage";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import InputErrorMsg from "../CustomComponents/InputErrorMsg";
import { BhavikaContext } from "../Context/Bhavika";

// import ErrorMsg from "../CustomComponents/ErrorMsg";

// import { API_BASE_URL } from '../../config';

const UserProfile = () => {
  //   const [profile, setProfile] = useState({
  //     name: "",
  //     phone: "",
  //     password: "",
  //     file: null,
  //   });
  const [show, setShow] = useState(false);
  const [myOrders, setMyOrders] = useState([]);
  // const [userProfile, setUserProfile] = useState([]);
  const [updatedUserProfile, setUpdatedUserProfile] = useState({
    name: "",
    phone: "",
    address: "",
  });
  const { user, setUser } = useContext(BhavikaContext);
  // const [editModalState, setEditModalState] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const initialUpdateProfileError = {
    name: "",
    phone: "",
    address: "",
  };

  const [updateProfileError, setUpdateProfileError] = useState(
    initialUpdateProfileError
  );

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      // setUserProfile(JSON.parse(localStorage.getItem("userData")));
      setUpdatedUserProfile(JSON.parse(localStorage.getItem("userData")));
    } else {
      window.location.href = "/";
    }
  }, []);

  // useEffect(()=>{

  //     axios.interceptors.request.use(
  //         config =>{
  //             config.headers.authorization=`Bearer ${localStorage.getItem("accessToken")}`;
  //             return config;
  //         },
  //         error =>{
  //             return Promise.reject(error)
  //         }
  //     )

  //         axios.get(`${API_BASE_URL}/api/v1/user/profile`)
  //     .then((res)=>{
  //         // setUserProfile(res.data)
  //         consoleLog('Responded data ')
  //         consoleLog(res.data.data)
  //         // setUserProfile(res.data.data)
  //     })
  //     .catch((err)=>{
  //         consoleLog(err);
  //         window.location.href = "/"
  //     })
  // })

  const formChangeHandler = (event) => {
    // Object Destructuring
    const { name, value } = event.target;

    setUpdatedUserProfile((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const updateUserData = async () => {
    setErrMsg("");
    setSuccessMsg("");
    setUpdateProfileError(initialUpdateProfileError);
    try {
      const { name, address, phone } = updatedUserProfile;
      if (!name) {
        setUpdateProfileError({ name: "Name is required" });
        return;
      }
      if (!phone) {
        setUpdateProfileError({ phone: "Mobile number is required" });
        return;
      } else {
        if (!/^\d{8}$/.test(phone)) {
          setUpdateProfileError({ phone: "Mobile number is 8 digits." });
          return;
        }
      }
      setSubmitting(true);
      const res = await api.patch(`/api/v1/user/profile`, {
        name,
        address,
        phone,
      });
      if (res && res.ok) {
        localStorage.setItem("userData", JSON.stringify(res.data));
        // setUserProfile(res.data);
        setUser(res.data);
        setSuccessMsg("User profile updated.");
        setTimeout(function () {
          setShow(false);
          setSuccessMsg("");
          setErrMsg("");
        }, 1000);
        setSubmitting(false);
      }
    } catch (err) {
      if (
        err &&
        err.errors &&
        Array.isArray(err.errors) &&
        err.errors.length > 0
      ) {
        setSubmitting(false);
        setErrMsg(err.errors[0].message);
      } else if (err && err.message) {
        setSubmitting(false);
        setErrMsg(err.message);
      } else {
        consoleError(err);
        setSubmitting(false);
        setErrMsg("Error updating profile.");
      }
    }
  };

  const cancelBtnHandler = () => {
    setShow(false);
    setSuccessMsg("");
    setErrMsg("");
    setUpdateProfileError({
      name: "",
      phone: "",
      address: "",
    });
  };

  return (
    <>
      <div className={"user-profile"}>
        {/* <ShopByHeading title="User Profile" />
        <h2><span>THIS IS A TEST</span></h2> */}
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-3 col-md-3 col-4  d-flex justify-content-center align-items-center">
              <span className="font-weight-bold pageTitle">User Profile</span>
            </div>
            <div className="col-lg-9 col-md-9 col-7">
              <h6>
                <span
                  onClick={() => setShow(true)}
                  // data-toggle="modal"
                  // data-target="#myModal"
                >
                  Edit Profile
                  {/* <i className="fa fa-edit ml-2"></i> */}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <UserAvatarComponents data={user} myOrders={myOrders} />
            <UserDetailsComponents data={user} />
          </div>
        </div>

        {/* Recent Orders Starts */}
        <div className="container-fluid mt-4 justify-content-center">
          <div className="row">
            <RecentOrdersComponents
              myOrders={myOrders}
              setMyOrders={setMyOrders}
            />
          </div>
        </div>
        {/* Recent Orders Ends */}
      </div>

      {/* Modal Starts */}
      {/* <div className="modal fade" id="myModal" style={{zIndex:'9999'}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Update Details</h4>
              <button type="button" className="close" data-dismiss="modal" onClick={cancelBtnHandler}>
                &times;
              </button>
            </div>

            <div className="modal-body">
              {successMsg ? <SuccessMessage msg={successMsg} />: null}
              {errMsg ? <FailedMessage msg={errMsg} /> : null}
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  name="name"
                  value={updatedUserProfile.name}
                  onChange={formChangeHandler}
                  required
                  maxLength="50"
                />
              </div>
              <div className="form-group">
                <label>Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  value={updatedUserProfile.phone}
                  onChange={formChangeHandler}
                  required
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  value={updatedUserProfile.address}
                  onChange={formChangeHandler}
                  maxLength="50"
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={cancelBtnHandler}
              >
                Close
              </button>
              <button
                type="button"
                className="btn"
                onClick={updateUserData}
                disabled={submitting}
                style={{backgroundColor:'yellowgreen',color:'white'}}
              >
                {submitting ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* Moda; starts */}

      {/* MODAL STARTS */}
      <Modal
        size="md"
        onHide={() => setShow(false)}
        show={show}
        aria-labelledby="address-modal"
        centered
        style={{ zIndex: "9999" }}
      >
        <ModalHeader>
          <ModalTitle>Update Profile</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {successMsg ? <SuccessMessage msg={successMsg} /> : null}
          {errMsg ? <FailedMessage msg={errMsg} /> : null}
          <form>
            <div className="form-group">
              <label>
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                value={updatedUserProfile.name}
                onChange={formChangeHandler}
                required
                maxLength="50"
              />
              {updateProfileError.name ? (
                <InputErrorMsg>{updateProfileError.name}</InputErrorMsg>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>
                Mobile Number (HK) <span style={{ color: "red" }}>*</span>
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    +852
                  </span>
                </div>
                <input
                  id="phone"
                  name="phone"
                  value={updatedUserProfile.phone}
                  onChange={formChangeHandler}
                  placeholder="Enter mobile number"
                  autoComplete="phone"
                  className=" form-control"
                  type="tel"
                  maxLength={8}
                />
              </div>
              {updateProfileError.phone ? (
                <InputErrorMsg>{updateProfileError.phone}</InputErrorMsg>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                name="address"
                value={updatedUserProfile.address}
                onChange={formChangeHandler}
                maxLength="200"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={cancelBtnHandler}
          >
            Close
          </button>
          <button
            type="button"
            className="btn"
            onClick={updateUserData}
            disabled={submitting}
            style={{ backgroundColor: "yellowgreen", color: "white" }}
          >
            {submitting ? "Saving..." : "Save"}
          </button>
        </ModalFooter>
      </Modal>
      {/* MODAL ENDS */}
    </>
  );
};
export default UserProfile;
