import React, { useContext, useEffect, useState } from "react";
import "../../scss/include/userAvatarComponents.scss";
import api from "../../services/api";
import {
  avatarUrl,
  getRewardDollar,
  setDefaultAvatar,
} from "../../utils/helper";
import { consoleError } from "../../utils/helper";
import { BhavikaContext } from "../Context/Bhavika";
const UserAvatarComponents = ({ data, myOrders }) => {
  const { setUser } = useContext(BhavikaContext);
  const TotalOrders = Array.isArray(myOrders) ? myOrders.length : 0;
  // const MoneySpent = Array.isArray(myOrders)
  //   ? myOrders.reduce((acc, item) => (acc += item.totalPrice), 0)
  //   : 0;
  const rewardBalance = data.reward_balance;
  const [avatar, setAvatar] = useState({
    file: null,
    url: null,
  });
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const changeImageFile = (e) => {
    e.preventDefault();
    const fileUpload = document.getElementById("file1");
    fileUpload.click();
  };
  const avatarChange = (e) => {
    e.preventDefault();
    // consoleLog(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      setAvatar({
        file: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
    }
  };
  const uploadUserAvatar = async (e) => {
    const formData = new FormData();
    formData.append("avatar", avatar.file);
    setUploading(true);
    setUploadError("");
    try {
      const res = await api.post("/api/v1/user/profile/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // const { url } = res.data;

      const userData = JSON.parse(localStorage.getItem("userData"));
      userData["avatar"] = res.data.url;
      localStorage.setItem("userData", JSON.stringify(userData));
      setUser(userData);
      setAvatar(res.data);
      setUploading(false);
    } catch (error) {
      consoleError(error);
      setAvatar({ file: null, url: null });
      setUploadError("Upload Error.");
      setUploading(false);
    }
  };
  useEffect(() => {
    if (data && data.avatar) {
      setAvatar({ file: null, url: data.avatar });
    }
  }, [data]);
  return (
    <div
      className="col-lg-3 col-md-3 col-12  d-flex justify-content-center align-items-center flex-column userAvatarMainDiv"
      style={{ borderRight: "1px grey solid " }}
    >
      {uploadError ? (
        <div className="alert alert-danger" role="alert">
          {uploadError}
        </div>
      ) : null}
      <div className="userImageDiv m-2">
        <img
          src={avatar.file ? avatar.url : avatarUrl(avatar.url)}
          onError={setDefaultAvatar}
          alt="user_avatar"
        />
        <input
          type="file"
          id="file1"
          name="image"
          onChange={avatarChange}
          accept="image/*"
          capture
          style={{ display: "none" }}
        />
        <div className="image-upload-btn" onClick={(e) => changeImageFile(e)}>
          <i className="fas fa-camera" />
        </div>
      </div>
      <span>
        <button
          onClick={uploadUserAvatar}
          style={
            avatar.file
              ? { display: "block", marginBottom: "5px" }
              : { display: "none" }
          }
          className="btn btn-outline-secondary"
          disabled={uploading}
        >
          {uploading ? "Uploading..." : "Upload Image"}
        </button>
        <button
          onClick={(e) => {
            setAvatar({ file: null, url: data.avatar });
            const fileUpload = document.getElementById("file1");
            fileUpload.value = "";
          }}
          style={avatar.file ? { display: "block" } : { display: "none" }}
          className="btn btn-outline-danger"
          disabled={uploading}
        >
          Cancel Upload
        </button>
      </span>
      <span className="font-weight-bold userName">{data.name}</span>

      <div className="detailsMainDiv  d-flex">
        <div className="totalOrderDetails d-flex justify-content-center align-items-center flex-column m-2">
          <span className="font-weight-bold">{TotalOrders}</span>
          <span>No of Orders</span>
        </div>
        <div className="totalSpentDetails d-flex justify-content-center align-items-center flex-column m-2">
          <span className="font-weight-bold">
            ${getRewardDollar(rewardBalance) || 0}
          </span>
          <span>Reward Dollar</span>
        </div>
      </div>
    </div>
  );
};
export default UserAvatarComponents;
