import React from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import "../../scss/include/helpPage.scss";

const HelpPage = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Help Center"
        toHref="/help"
      />

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <span className="helpPageHeading">
              Frequently Asked Questions (FAQ)
            </span>
          </div>
        </div>
      </div>

      {/* Question Starts */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div id="accordion">
              {/* Question Card */}
              <div className="card">
                <div
                  className="card-header btn btn-link d-flex justify-content-start"
                  style={{ textDecoration: "none" }}
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  id="headingOne"
                >
                  <h5 className="mb-0">
                    <span className="helpQuestionText">
                      When will I receive my order?
                    </span>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <span className="helpAnswerText">
                      Please refer to our <a href="/shipping">shipping page</a>{" "}
                      where it's mentioned in details area wise.
                    </span>
                  </div>
                </div>
              </div>
              {/* Question Cards Ends*/}
              {/* Question Card 2 */}
              <div className="card">
                {/* <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <span className="helpQuestionText">How do I pay on Bhavika?</span>
                                </button>
                            </h5>
                            </div> */}
                <div
                  className="card-header btn btn-link d-flex justify-content-start"
                  style={{ textDecoration: "none" }}
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  id="headingTwo"
                >
                  <h5 className="mb-0">
                    <span className="helpQuestionText">
                      How do I pay on Bhavika?
                    </span>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <span className="helpAnswerText">
                      You can easily pay using PayMe, Credit Card or Cash On
                      Delivery. Please vist{" "}
                      <a href="/payment-methods">Payment Section</a> for more
                      details.
                    </span>
                  </div>
                </div>
              </div>
              {/* Question Card 2 Ends*/}
              {/* Question Card 2 */}
              <div className="card">
                {/* <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span className="helpQuestionText">How do I cancel my order?</span>
                                </button>
                            </h5>
                            </div> */}
                <div
                  className="card-header btn btn-link d-flex justify-content-start"
                  style={{ textDecoration: "none" }}
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  id="headingThree"
                >
                  <h5 className="mb-0">
                    <span className="helpQuestionText">
                      How do I cancel my order?
                    </span>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <span className="helpAnswerText">
                      Please login to your account online or WhatsApp us on{" "}
                      <a href="https://wa.me/+85297777510">+852-9777-7510</a> or
                      call us on <a href="tel:+85224154777">+852-2415-4777</a>{" "}
                      same day of placing the order.
                    </span>
                  </div>
                </div>
              </div>
              {/* Question Card 2 Ends*/}
            </div>
          </div>
        </div>
      </div>
      {/* Question Ends */}
    </>
  );
};
export default HelpPage;
