import React from "react";

import "../../scss/include/shopByCategoryCard.scss";
import { imageUrl } from "../../utils/helper";
import LazyLoad from "react-lazyload";

const ShopByCategoryCard = (props) => {
  return (
    <div className="shop-cat-card col-sm-6 col-md-6 col-lg-4 col-12">
      <a
        href={props.redirectionLink}
        style={{ textDecoration: "none", color: "#231f20" }}
      >
        <LazyLoad>
          <div className="card">
            <img
              className="card-img-top img-fluid"
              src={imageUrl(props.imageName)}
              loading="lazy"
              alt={props.heading}
            />
            <div className="card-body">
              <h5 className="card-title">{props.heading}</h5>
            </div>
          </div>
        </LazyLoad>
      </a>
    </div>
  );
};
export default ShopByCategoryCard;
