import React from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import DownloadOurAPpImgComponent from "../CustomComponents/DownloadOurAPpImgComponent";
import OurIntroduction from "../CustomComponents/OurIntroduction";
// import OurStaff from "../CustomComponents/OurStaff";
import OurStores from "../CustomComponents/OurStores";
import "../../scss/include/_aboutUs.scss";
import useTrans from "../hooks/useTrans";

const AboutUs = () => {
  const trans = useTrans();
  return (
    <div className={"about-us p-0"}>
      <BreadcrumbComponent
        from={trans.home}
        fromHref="/"
        to={trans.aboutUs}
        toHref="/about"
      />
      <OurIntroduction />
      <OurStores />
      {/* <OurStaff /> */}
      <DownloadOurAPpImgComponent />
    </div>
  );
};
export default AboutUs;
