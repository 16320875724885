import React, { createContext, useEffect, useState } from "react";
import api from "../../services/api";
import localCart from "../../services/cart";
import { consoleError } from "../../utils/helper";
import reallocalCart from "../../services/realCart";

export const CartContext = createContext();

const CartContextProvider = (props) => {
  const [realcart,setRealCart]=useState([]);
  const [cart, setCart] = useState([]);
  useEffect(() => {
    async function getCartItems() {
      try {
        if (!localStorage.getItem("accessToken")) {
          setCart(localCart.getCart());
          setRealCart(reallocalCart.getCart());
          return;
        }
        const res = await api.get("api/v1/user/cart");
        if (res && res.ok) {
          setCart(res.data);
        }
        const res1 = await api.get('api/v1/user/realcart');
        if (res1 && res1.ok) {
          console.log(res1);
          setRealCart(res1.data);
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getCartItems();
  }, []);

  return (
    <CartContext.Provider value={{ cart, setCart, realcart, setRealCart }}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
