import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import BestBuyContextProvider from "./components/Context/BestBuy";
import BhavikaContextProvider from "./components/Context/Bhavika";
import BrandContextProvider from "./components/Context/Brand";
import CartContextProvider from "./components/Context/Cart";
import CategoryContextProvider from "./components/Context/Category";
import FeaturedContextProvider from "./components/Context/Featured";
import ProductContextProvider from "./components/Context/Product";
import PromotionContextProvider from "./components/Context/Promotion";
import SubCategoryContextProvider from "./components/Context/SubCategory";
import Navbar from "./components/include/Navbar";
// import Header from "./components/Pages/Header";
import Footer from "./components/Pages/Footer";

function App() {
  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID}>
      <PromotionContextProvider>
        <SubCategoryContextProvider>
          <ProductContextProvider>
            <CartContextProvider>
              <BestBuyContextProvider>
                <FeaturedContextProvider>
                  <BrandContextProvider>
                    <CategoryContextProvider>
                      <BhavikaContextProvider>
                        <Navbar />
                        <Footer />
                      </BhavikaContextProvider>
                    </CategoryContextProvider>
                  </BrandContextProvider>
                </FeaturedContextProvider>
              </BestBuyContextProvider>
            </CartContextProvider>
          </ProductContextProvider>
        </SubCategoryContextProvider>
      </PromotionContextProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
