import React from "react";
import FacebookLogin from "react-facebook-login";
import { API_BASE_URL, FACEBOOK_APP_ID } from "../../config";
import qs from "qs";
import { consoleError, consoleLog } from "../../utils/helper";
import api from "../../services/api";
import localCart from "../../services/cart";
import { BhavikaContext } from "../Context/Bhavika";
import { useContext } from "react";
import { CartContext } from "../Context/Cart";
import reallocalCart from "../../services/realCart";


export default function FacebookLoginButton({ setErrorMessage }) {
  const { setIsAuthenticated, setLoginShow, setUser } = useContext(
    BhavikaContext
  );
  const { setCart, setRealCart } = useContext(CartContext);
  const responseFacebook = async (res) => {
    setErrorMessage("");
    consoleLog({ res });
    try {
      // const code = JSON.parse(atob(res.signedRequest.split(".")[1])).code;
      // const { accessToken } = res;
      // consoleLog({ accessToken });
      if (!res) {
        setErrorMessage("Facebook login failed. Please try again.");
        return;
        // throw new Error("no data on response");
      }
      const { grantedScopes, accessToken } = res;
      if (!grantedScopes) {
        setErrorMessage("Facebook login failed. Please try again.");
        return;
      } else {
        const granted = String(grantedScopes).split(",");
        if (!granted.includes("email")) {
          setErrorMessage("Please grant email permission.");
          return;
        }
      }
      if (!accessToken) {
        setErrorMessage("Facebook login failed. Please try again.");
        return;
      }
      let query = qs.stringify({ accessToken });
      if (accessToken) {
        try {
          const res = await api.get(
            `${API_BASE_URL}/api/v1/auth/facebook?${query}`
          );
          if (res && res.ok) {
            consoleLog(res);
            localStorage.setItem("accessToken", res.accessToken);
            localStorage.setItem("userData", JSON.stringify(res.user));
            setUser(res.user);
            setIsAuthenticated(true);

            if (localCart.getCart().length > 0) {
              const res = await api.post("/api/v1/user/cart/localcart", {
                cart: localCart.getCart(),
              });
              if (res && res.ok) {
                localCart.emptyCart();
                setCart(res.data);
              }
              setLoginShow(false);
            }
            if (reallocalCart.getCart().length > 0) {
              const res = await api.post("/api/v1/user/realcart/localcart", {
                cart: reallocalCart.getCart(),
              });
              if (res && res.ok) {
                reallocalCart.emptyCart();
                setRealCart(res.data);
              }
              setLoginShow(false);
            }
          }
        } catch (err) {
          consoleError(err);
          setErrorMessage(
            err.message
              ? err.message
              : "Facebook login failed. Please try again."
          );
        }
      } else {
        consoleLog("No 'code' on Facebook response.");
        setErrorMessage("Facebook login failed. Please try again.");
      }
    } catch (error) {
      consoleLog(
        error && error.message
          ? error.message
          : "No 'code' on Facebook response."
      );
      setErrorMessage("Facebook login failed. Please try again.");
    }
  };
  return (
    <div>
      <FacebookLogin
        size="small"
        textButton="Facebook"
        fields="name,email,picture"
        scope="public_profile,email"
        returnScopes={true}
        authType="rerequest"
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        callback={responseFacebook}
        icon="fa-facebook"
        isMobile={false}
        disableMobileRedirect={true}
      />
    </div>
  );
}
