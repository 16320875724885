import React from "react";
import "../../scss/include/inStoreAdvertisment.scss";
import AdvertismentTypeCard from "../CustomComponents/AdvertismentTypeCard";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import ShopByHeading from "../CustomComponents/ShopByHeading";
import OutDoorImg from "../../assets/Others/OutDoorAdv.jpg";
import billboardImg from "../../assets/Others/bilboardAdv.jpg";
import entrygateImg from "../../assets/Others/entryGate.jpg";
import AdvertismentTypeHeaderSection from "../CustomComponents/AdvertismentTypeHeaderSection";

const OutdoorAdvertisment = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Advertise with us"
        toHref="/advertise-with-us"
        thirdPage="Outdoor Advertisment"
        thirdPageHref="/advertise-with-us/Outdoor"
      />

      <AdvertismentTypeHeaderSection />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column justify-content-center ">
            <span className="advDescInStore">
              Bhavika Online has 253 + stores spread across 135 + cities and a
              combined retail space of approx. 10.5 million sq.ft .The huge
              stores offer high visible facades/billboards to advertisers at the
              entrance of the store. The millions of customers entering the
              store to shop have undivided attention when entering the stores.
              Hence the right messaging on these facades combined with the large
              size of the façade can deliver the perfect brand message to these
              shoppers. Each of these facades vary in sizes and details can be
              provided basis store and catchment are preferences.
            </span>
            <span className="advDescInStore mt-2">
              To know more about the Outdoor advertising, please feel free to
              contact us via email:{" "}
              <a
                href="mailto:info@bhavika.online"
                style={{ textDecoration: "underline" }}
              >
                info@bhavika.online
              </a>
            </span>
          </div>
        </div>
      </div>
      <ShopByHeading title="Outdoor Advertisment Images" />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 flex-wrap d-flex h-auto ">
            <AdvertismentTypeCard
              imgName={entrygateImg}
              contain="Advertisment in Entry Gate"
            />
            <AdvertismentTypeCard
              imgName={billboardImg}
              contain="Advertisment in Parking Space"
            />
            <AdvertismentTypeCard
              imgName={OutDoorImg}
              contain="Advertisment in Buildings"
            />
            <AdvertismentTypeCard
              imgName={billboardImg}
              contain="Advertisment in billboard"
            />
            <AdvertismentTypeCard
              imgName={OutDoorImg}
              contain="Advertisment in Buildings"
            />
            <AdvertismentTypeCard
              imgName={billboardImg}
              contain="Advertisment in billboard"
            />
            <AdvertismentTypeCard
              imgName={entrygateImg}
              contain="Advertisment in Entry Gate"
            />
            <AdvertismentTypeCard
              imgName={billboardImg}
              contain="Advertisment in Parking Space"
            />
            <AdvertismentTypeCard
              imgName={OutDoorImg}
              contain="Advertisment in Buildings"
            />
            <AdvertismentTypeCard
              imgName={billboardImg}
              contain="Advertisment in billboard"
            />
            <AdvertismentTypeCard
              imgName={OutDoorImg}
              contain="Advertisment in Buildings"
            />
            <AdvertismentTypeCard
              imgName={billboardImg}
              contain="Advertisment in billboard"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OutdoorAdvertisment;
