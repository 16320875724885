import React, { useContext, useEffect, useState } from "react";
import { BhavikaContext } from "../Context/Bhavika";
import BlogCard from "../CustomComponents/BlogCard";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import Pagination from "../CustomComponents/Pagination";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";
import PageSpinner from "../CustomComponents/PageSpinner";
import "../../scss/include/blogPage.scss";

const BlogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const [loading, setLoading] = useState(false);

  const { blogs, setBlogs } = useContext(BhavikaContext);

  useEffect(() => {
    const getBlogs = async () => {
      setLoading(true);
      try {
        const res = await api.get("/api/v1/blog");
        if (res && res.ok) {
          setBlogs(res.data);
        }
      } catch (error) {
        consoleError(error);
      } finally {
        setLoading(false);
      }
    };
    getBlogs();
  }, [setBlogs]);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <BreadcrumbComponent from="Home" fromHref="/" to="Blog" toHref="/blog" />
      {/* {posts.map((data,index)=>(
            <BlogCard data={data}/>
            ))} */}

      <div className="container-fluid">
        <div className="row  d-flex justify-content-between align-items-center">
          <div className="col-lg-12 col-md-12 col-12 mb-2">
            <h2
              style={{
                // fontSize: "17px",
                fontWeight: "600",
                letterSpacing: "1px",
                lineHeight: "1.7",
                wordSpacing: "2px",
              }}
            >
              Bhavika Blogs
            </h2>
          </div>
          {/* <div className="col-lg-3 colmd-3 col-3">
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={blogs.length}
              paginate={paginate}
            />
          </div> */}
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : blogs && blogs.length > 0 ? (
        <div className="row">
          <BlogCard posts={currentPosts} />
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <div className="text-center text-muted mb-2">
            <h4>We are coming with the blogs very soon.</h4>
          </div>
        </div>
      )}

      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={blogs.length}
        paginate={paginate}
        className="pagination"
      />
    </>
  );
};
export default BlogPage;
