import React, { useEffect, useState } from "react";
import api from "../../services/api";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import PageSpinner from "../CustomComponents/PageSpinner";
import ProductListWithSearch from "../CustomComponents/ProductListWithSearch";
import ShopByCategoryCard from "../CustomComponents/ShopByCategoryCard";
import ShopByHeading from "../CustomComponents/ShopByHeading";
import "../../scss/include/promotionProductListPage.scss";
import { consoleError } from "../../utils/helper";

export default function PromotionProductListPage({ match }) {
  const [pageLoading, setPageLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [details, setDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    params: { slug },
  } = match;
  useEffect(() => {
    async function getDataBySlug() {
      try {
        const res = await api.get(`/api/v1/promotion/slug/${slug}`);
        if (res && res.ok) {
          if (res.data) {
            const { category, subcategory, brand, product, ...details } =
              res.data;
            setDetails(details);
            if (product) setProductList(product);
            if (category) setCategoryList(category);
            if (brand) setBrandList(brand);
            if (subcategory) setSubcategoryList(subcategory);
          }
        }
        setPageLoading(false);
      } catch (error) {
        consoleError(error);
        setErrorMessage(
          error && error.message
            ? error.message
            : `Promotion ${slug} not found.`
        );
        setPageLoading(false);
      }
    }
    if (slug) getDataBySlug();
  }, [slug]);
  return (
    <div>
      {pageLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : details ? (
        <>
          <BreadcrumbComponent
            from="Home"
            fromHref="/"
            to={"Promotion"}
            toHref={`/promotion/${details.slug}`}
            thirdPage={details.name}
            thirdPageHref={`/promotion/${details.slug}`}
          />
          <div className="d-flex justify-content-end align-items-end">
            <div className="d-flex justify-content-center align-items-center w-auto couponCodeMainDiv mr-3">
              <h3 className="text-center">
                <em>Apply Coupon code:</em> {details.coupon}
              </h3>
            </div>
          </div>
          {categoryList.length > 0 ? (
            <>
              <ShopByHeading title="Promotion Category" />
              <div className="container">
                <div className="row">
                  {categoryList.map((cat, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ShopByCategoryCard
                          heading={cat.name}
                          imageName={cat.banner}
                          redirectionLink={`/category/${cat.slug}`}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
          {subcategoryList.length > 0 ? (
            <>
              <ShopByHeading title="Promotion SubCategory" />
              <div className="container">
                <div className="row">
                  {subcategoryList.map((subcat, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ShopByCategoryCard
                          heading={subcat.name}
                          imageName={subcat.banner}
                          redirectionLink={`/category/${subcat.category.slug}/${subcat.slug}`}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
          {brandList.length > 0 ? (
            <>
              <ShopByHeading title="Promotion Brand" />
              <div className="container">
                <div className="row">
                  {brandList.map((brand, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ShopByCategoryCard
                          heading={brand.name}
                          imageName={brand.logo}
                          redirectionLink={`/brand/${brand.slug}`}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
          {productList.length > 0 ? (
            <ProductListWithSearch productList={productList} discount_type={details.discount_type} discount={details.discount} promotion_on={details.promotion_on} />
          ) : null}
        </>
      ) : (
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="text-center">
              {errorMessage ? (
                <h3>{errorMessage}</h3>
              ) : (
                <h3>{`Promotion "${slug}" not found.`}</h3>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
