import React,{useState,useEffect, useContext} from "react";
import useTrans from "../hooks/useTrans";
import "../../scss/include/_realTasteOfIndiaSidebar.scss";

import payment1 from "../../assets/icons/payme.png";
import payment2 from "../../assets/icons/fps.png";
import payment3 from "../../assets/icons/bank.png";
import api from "../../services/api";
import reallocalCart from "../../services/realCart";
import { CartContext } from "../Context/Cart";
import { useHistory } from "react-router";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPhoneAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { consoleError, productUrl} from "../../utils/helper";
import {
  Button,
  Modal,
} from "react-bootstrap";


const RealTasteOfIndiaSidebar = () => {
  const trans = useTrans();
  const [category,setCategory]=useState(null);
  const { setRealCart } = useContext(CartContext);
  const history = useHistory();



  useEffect(()=>{
    getCategoryDetails();
  },[])

  const getCategoryDetails = async () =>{
    try {
      const res = await api.get(`/api/v1/category/slug/real-taste-of-india`);
      if (res && res.data) {
        // console.log(res.data)
        setCategory(res.data);
      }
    } catch (error) {
      consoleError(error);
      }
  }

  const [addedQuantity, setAddedQuantity] = useState(0);
  const [addedProduct,setAddedProduct]=useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);


  const openProductSuccessModal = (quantity) => {
    setAddedQuantity(quantity);
    setShowSuccessModal(true);
  };
  
  const closeProductSuccessModal = () => {
    setAddedQuantity(0);
    setShowSuccessModal(false);
  };

  const addToCartButton = async (product) => {
    // alert(
    //   `Product Image Name: ${product.banner}, Product Name is: ${product.name}, Price is: ${product.price}, Kgs is: ${product.unit}, Quantity is: ${productQuantity}`
    // );
    if (localStorage.userData) {
      try {
        let res = await api.post("/api/v1/user/realcart/add", {
          product: product._id,
          quantity: 1,
        });
        if (res && res.ok) {
          console.log(res.data);
          setRealCart(res.data);
          openProductSuccessModal(1);
          setAddedProduct(product);
        }
      } catch (error) {
        consoleError(error);
      }
    } else {
      // setShowLoginMsg(true);
      reallocalCart.addProduct(product, 1);
      setRealCart(reallocalCart.getCart());
      openProductSuccessModal(1);
      setAddedProduct(product);
    }
  };

  return (
    <>
    <div className="real-taste-of-india-sidebar">
      <div className="todays-special">
        <h1>Today's Special:</h1>
        <div className="special-items">
          {
            category
            ?
            category.subcategory.length > 0
            ?
            category.subcategory[3].products.slice(0,2).map((data,index)=>(
          <div className="special-item" key={index} >
            <img src={productUrl(data.banner)} alt="special item" />
            <p className="item-name">{data.name}</p>
            <div className="side-by-side">
              <span className="price">HK$ {data.price}</span>
              <div style={{cursor:'pointer'}} onClick={()=>addToCartButton(data)} className="add-to-cart">
                <FontAwesomeIcon icon={faShoppingCart} />
              </div>
            </div>
          </div>
            ))
            :
            ""
            :
            ""
          }
        </div>
      </div>
      <div className="order-info">
        <p>
          <FontAwesomeIcon icon={faPhoneAlt} />
          <a href="tel:24154777">2415 4777</a>
          <a href="tel:91831538">9183 1538</a>
        </p>
        <p>
          <FontAwesomeIcon icon={faClock} />
          Last order before 9.30 PM. Please call above numbers to confirm your kitchen order details.
        </p>
      </div>
      <div className="accepted-payment">
        <h1>Accepted Payments:</h1>
        <div className="">
          <img src={payment1} alt="accepter payment" />
          {/* <img src={payment2} alt="accepter payment" /> */}
          <img src={payment3} alt="accepter payment" />
        </div>
      </div>
    </div>

    {
  addedProduct
  ?
  <Modal
     size="lg"
     show={showSuccessModal}
     onHide={closeProductSuccessModal}
     aria-labelledby="example-modal-sizes-title-lg"
     className="modelMainDiv"
     centered
   >
     <Modal.Header>
       <h5>This item has been added to the cart.</h5>
       <button
         className="btn btn-outline-secondary btn-circle"
         onClick={closeProductSuccessModal}
       >
         <i className="fa fa-times"></i>
       </button>
     </Modal.Header>
     <Modal.Body>
       <div>
         <div className="row">
           <div className="col-lg-4 col-md-6 col-sm-12">
             <img
               src={productUrl(addedProduct.banner)}
               className="img-fluid imgSize"
               alt="cat-product"
             />
           </div>
           <div className="col-lg-8 col-md-6 col-sm-12">
             <div>
               <span className="font-weight-bold">Product: </span>
               {addedProduct.name}
               {/* {transName(addedProduct.name)} */}
             </div>
             <div>
               <span className="font-weight-bold">Quantity: </span>
               {addedQuantity}
             </div>
           </div>
         </div>
       </div>
       <div className="container mt-4 mb-3 d-flex justify-content-between flex-wrap">
         <Button
           variant="info"
           className="rounded-pill"
           onClick={closeProductSuccessModal}
         >
           {trans.continueShopping}
         </Button>
         <Button
           variant="success"
           className="rounded-pill"
           onClick={() => {
             history.push("/carts");
           }}
         >
           {trans.proceedToCart}
         </Button>
       </div>
     </Modal.Body>
   </Modal>
  :
  ""
}
</>
  );
};
export default RealTasteOfIndiaSidebar;
