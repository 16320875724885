import React, { useContext, useEffect, useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import "../../scss/include/cart.scss";
import { CartContext } from "../Context/Cart";
import { imageUrl } from "../../utils/helper";
import api from "../../services/api";
import { ServiceTax, VAT } from "../../utils/constants";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { consoleError } from "../../utils/helper";
import Modal from "react-bootstrap/Modal";
import { BhavikaContext } from "../Context/Bhavika";
import reallocalCart from "../../services/realCart";
import useTrans from "../hooks/useTrans";

// import CartHeadingComponent from "../CustomComponents/CartHeadingComponent";
// import CartProductComponent from "../CustomComponents/CartProductComponent";
// import CartSubTotalAndTaxCalculationComponents from "../CustomComponents/CartSubTotalAndTaxCalculationComponents";
// import CartPageButtonsComponents from "../CustomComponents/CartPageButtonsComponents";

const RealCart = () => {
  const history = useHistory();
  const trans = useTrans();
  const { realcart, setRealCart } = useContext(CartContext);
  const { isAuthenticated, setLoginShow } = useContext(BhavikaContext);

  const [showEmptyCartModal, setShowEmptyCartModal] = useState(false);

  useEffect(() => {
    async function getCartItems() {
      try {
        if (isAuthenticated) {
          const res = await api.get("api/v1/user/realcart");
          if (res && res.ok) {
            setRealCart(res.data);
          }
        } else {
            setRealCart(reallocalCart.getCart());
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getCartItems();
  }, [isAuthenticated, setRealCart]);
  const updateCart = async (product, action) => {
    try {
      if (isAuthenticated) {
        let res = await api.post("/api/v1/user/realcart/update", {
          product,
          action,
        });
        if (res && res.data) {
            setRealCart(res.data);
        }
      } else {
        if (action === "plus") {
          reallocalCart.addOneProduct(product);
        }
        if (action === "minus") {
          reallocalCart.removeOneProduct(product);
        }
        setRealCart(reallocalCart.getCart());
      }
    } catch (error) {
      consoleError(error);
    }
  };
  const removeFromCart = async (product) => {
    try {
      if (isAuthenticated) {
        let res = await api.post("/api/v1/user/realcart/remove", {
          product,
        });
        if (res && res.ok) {
          setRealCart(res.data);
        }
      } else {
        reallocalCart.removeProduct(product);
        setRealCart(reallocalCart.getCart());
      }
    } catch (error) {
      consoleError(error);
    }
  };
  const resetCart = async () => {
    // const confirm = window.confirm("Are you sure you want to empty the cart?");
    //  confirm(true);
    // if (confirm) {
    try {
      if (isAuthenticated) {
        let res = await api.get("/api/v1/user/realcart/reset");
        if (res && res.ok) {
          setRealCart(res.data);
          setShowEmptyCartModal(false);
        }
      } else {
        reallocalCart.emptyCart();
        setRealCart(reallocalCart.getCart());
        setShowEmptyCartModal(false);
      }
    } catch (error) {
      consoleError(error);
    }
    // }
  };
  const SubTotal = realcart
    .reduce((acc, item) => (acc += item.quantity * item.product?.price), 0)
    .toFixed(2);

  const Total = (
    ((100 + VAT) / parseFloat(100)) *
    (((100 + ServiceTax) / parseFloat(100)) * SubTotal)
  ).toFixed(2);

  const closeEmptyCartModal = () => {
    setShowEmptyCartModal(false);
  };

  return (
    <>
      <div className={"cart-section"}>
        <BreadcrumbComponent
          from="Real Tast Of India"
          fromHref="/real-taste-of-india"
          to="Carts"
          toHref="/carts"
        />
        {/* CART HEADING STARTS */}
        <div className="container">
          <div className="table-responsive">
            <table className="table table-striped cart-table">
              <thead>
                <tr>
                  <th className={"text-center"}>Items</th>
                  <th className={"text-center"}>Quantity</th>
                  <th className={"text-center"}>Price</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {realcart && Array.isArray(realcart) && realcart.length > 0 ? (
                  realcart.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "30%" }}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 align-items-center">
                            <img
                              src={imageUrl(item.product?.banner)}
                              className="img-fluid"
                              alt="cart product"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 align-items-center ">
                            {item.product?.name}
                            {/* <br />
                            {item.product.weight
                              ? item.product.weight.value +
                                item.product.weight.unit
                              : null} */}
                          </div>
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <span
                          onClick={() => {
                            updateCart(item.product._id, "minus");
                          }}
                        >
                          <i
                            className="fa fa-minus"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                        &nbsp;&nbsp;
                        <span className={"text"}>{item.quantity}</span>
                        &nbsp;&nbsp;
                        <span
                          onClick={() => {
                            updateCart(item.product._id, "plus");
                          }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </td>
                      <td className={"text-center"}>
                        <span className="text">
                          ${Number(item.product?.price).toFixed(2)}
                        </span>
                      </td>
                      <td className={"text-left"} style={{ width: "10%" }}>
                        <span
                          onClick={() => {
                            removeFromCart(item.product._id);
                          }}
                        >
                          <i
                            className="fa fa-times cancel-product"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <p className="text-center">
                        No items in cart. Please head to{" "}
                        <Link to="/real-taste-of-india">home</Link> and continue shopping
                      </p>
                    </td>
                  </tr>
                )}
                <tr className={"sub-heading"}>
                  <td></td>
                  <td className="d-flex justify-content-end align-items-center">
                    SubTotal
                  </td>
                  <td>${SubTotal}</td>
                  <td></td>
                </tr>
                <tr className={"sub-heading"}>
                  <td></td>
                  <td className="d-flex justify-content-end align-items-center">
                    Service Tax
                  </td>
                  <td>{ServiceTax}%</td>
                  <td></td>
                </tr>
                <tr className={"sub-heading"}>
                  <td></td>
                  <td className="d-flex justify-content-end align-items-center">
                    VAT
                  </td>
                  <td>{VAT}%</td>
                  <td></td>
                </tr>
                <tr className={"sub-heading"}>
                  <td></td>
                  {/* <td className={"text-center"}>Total</td> */}
                  <td className="d-flex justify-content-end align-items-center">
                    Total
                  </td>
                  <td>${Total}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12 discount-section">
              <br />
              <button
                className={"btn btn-sm btn-danger w-25"}
                disabled={realcart && Array.isArray(realcart) && realcart.length < 1}
                // onClick={resetCart}
                onClick={() => setShowEmptyCartModal(true)}
              >
                {trans.emptyCart}
              </button>
            </div>
            <div className="col-md-6 col-sm-6 col-12 checkout-section">
              <Button
                className={"btn btn-sm rounded-pill"}
                type={"submit"}
                onClick={() => {
                  if (isAuthenticated) {
                    history.push("/checkouts");
                  } else {
                    setLoginShow(true);
                  }
                }}
                disabled={
                  !realcart || (realcart && Array.isArray(realcart) && realcart.length < 1)
                }
              >
                {trans.checkout}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Emptycart MODAL */}
      <Modal
        size="md"
        show={showEmptyCartModal}
        onHide={closeEmptyCartModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>Empty Cart?</h5>
          <button
            class="btn btn-outline-secondary btn-circle"
            onClick={closeEmptyCartModal}
          >
            <i class="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to empty your cart?
          <br />
          <div className="container d-flex justify-content-end align-items-center mt-2">
            <Button
              variant="outline-info mr-2 rounded-pill"
              onClick={closeEmptyCartModal}
            >
              cancel
            </Button>
            <Button variant="outline-success rounded-pill" onClick={resetCart}>
              {"Yes"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RealCart;
