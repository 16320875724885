import React from "react";

const SuccessMessage = (props) => {
  return (
    <>
      <div className="alert alert-primary text-center" role="alert">
        {props.msg}
      </div>
    </>
  );
};
export default SuccessMessage;
