import React from "react";
import "../../scss/include/storeLocator.scss";

export default function StoreLocator() {
  return (
    <div className="d-flex align-item-center justify-content-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.5377490346177!2d113.94354917591433!3d22.295493579690003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403e3620100ce49%3A0x737d1119b2b381ba!2sTbay!5e0!3m2!1sen!2snp!4v1720090006836!5m2!1sen!2snp"
        width="800"
        height="600"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
