import React, { useContext, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";

import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import api from "../../services/api";
import { consoleError, productUrl } from "../../utils/helper";
import "../../scss/include/productPage.scss";

import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import PageCenter from "../CustomComponents/PageCenter";
import PageSpinner from "../CustomComponents/PageSpinner";
import useTransPara from "../hooks/useTransPara";
import { CartContext } from "../Context/Cart";
import { BhavikaContext } from "../Context/Bhavika";
import localCart from "../../services/cart";
import useTrans from "../hooks/useTrans";
import ReactOwlCarousel from "react-owl-carousel";
import { Fragment } from "react";

const Heading = ({ title }) => (
  <Container fluid>
    <h3
      style={{
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        fontSize: "20px",
        fontWeight: "bold",
      }}
    >
      {title}
    </h3>
  </Container>
);

function ProductPage() {
  const transName = useTransPara("name");
  const trans = useTrans();
  const { setCart } = useContext(CartContext);
  const { setLoginShow, isAuthenticated } = useContext(BhavikaContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [product, setProduct] = useState(null);

  const [productQuantity, setProductQuantity] = useState(1);

  const [submitting, setSubmitting] = useState(false);
  const [wishlistSubmitting, setWishlistSubmitting] = useState(false);
  const [showLoginMsg, setShowLoginMsg] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [addedQuantity, setAddedQuantity] = useState(0);

  const [addedToWishlist, setAddedToWishlist] = useState(false);

  const [startSimilarFetch, setStartSimilarFetch] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarFetching, setSimilarFetching] = useState(true);

  const getSimilarProducts = async (id) => {
    setStartSimilarFetch(true);
    try {
      setSimilarFetching(true);
      let res = await api.get(`/api/v1/product/${id}/similar`);
      if (res && res.ok) {
        setSimilarProducts(res.data);
      }
    } catch (error) {
      consoleError(error);
    } finally {
      setSimilarFetching(false);
    }
  };
  const openProductSuccessModal = (quantity) => {
    setAddedQuantity(quantity);
    setShowSuccessModal(true);
  };

  const closeProductSuccessModal = () => {
    setAddedQuantity(0);
    setShowSuccessModal(false);
  };

  const addToCartButton = async () => {
    // alert(
    //   `Product Image Name: ${product.banner}, Product Name is: ${product.name}, Price is: ${product.price}, Kgs is: ${product.unit}, Quantity is: ${productQuantity}`
    // );
    if (isAuthenticated) {
      setSubmitting(true);
      try {
        let res = await api.post("/api/v1/user/cart/add", {
          product: product._id,
          quantity: productQuantity,
        });
        if (res && res.ok) {
          setCart(res.data);
          setSubmitting(false);
          openProductSuccessModal(productQuantity);
          setProductQuantity(1);
        }
      } catch (error) {
        consoleError(error);
        setSubmitting(false);
      }
    } else {
      // setShowLoginMsg(true);
      localCart.addProduct(product, productQuantity);
      setCart(localCart.getCart());
      openProductSuccessModal(productQuantity);
      setProductQuantity(1);
    }
  };

  const addToWishlist = async () => {
    if (isAuthenticated) {
      setWishlistSubmitting(true);
      try {
        let res = await api.post("/api/v1/user/wishlist", {
          action: "add",
          product: product._id,
        });
        if (res && res.ok) {
          setAddedToWishlist(true);
          setWishlistSubmitting(false);
        }
      } catch (error) {
        consoleError(error);
        setWishlistSubmitting(false);
      }
    } else {
      setShowLoginMsg(true);
    }
  };
  const headToLogin = () => {
    setShowLoginMsg(false);
    setLoginShow(true);
  };

  const decreaseProductQuantity = () => {
    const quantity = parseInt(productQuantity);
    if (productQuantity <= 1) {
      setProductQuantity(1);
    } else {
      setProductQuantity(quantity - 1);
    }
  };
  const increaseProductQuantity = () => {
    const quantity = parseInt(productQuantity);
    setProductQuantity(quantity + 1);
  };

  let { slug } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function getProductData() {
      try {
        setLoading(true);
        const res = await api.get(`/api/v1/product/slug/${slug}`);
        if (res && res.ok) {
          setProduct(res.data);
          setLoading(false);
          getSimilarProducts(res.data._id);
        }
      } catch (error) {
        consoleError(error);
        const message = error && error.message;
        setError(message || "Error getting product details.");
      } finally {
        setLoading(false);
      }
    }
    getProductData();
    setAddedToWishlist(false);
    setProductQuantity(1);
  }, [slug]);

  if (loading) {
    return (
      <PageCenter>
        <PageSpinner />
      </PageCenter>
    );
  }
  if (error) {
    return (
      <>
        <h1>error</h1>
        <p1>{error}</p1>
      </>
    );
  }

  return (
    <Fragment key={slug}>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to={transName(product.category)}
        toHref={`/category/${product.category.slug}`}
        thirdPage={transName(product.subcategory)}
        thirdPageHref={`/category/${product.category.slug}/${product.subcategory.slug}`}
        fourth={transName(product)}
        fourthHref={`/products/${product.slug}`}
      />
      <Container fluid className="product-page">
        <Row>
          <div
            className="back"
            onClick={() => {
              history.goBack();
            }}
          >
            <span className="backIcon">
              <i className="fas fa-arrow-left fa-sm" />
            </span>
            Back
          </div>
        </Row>
        <Row className="mt-5 mb-5 ">
          <Col sm="12" md="4" className="product-image-col mb-5">
            <LazyLoad>
              <div className="product-image">
                <img
                  src={productUrl(product.banner)}
                  className="img-fluid"
                  alt="product"
                />
              </div>
            </LazyLoad>
          </Col>
          <Col sm="12" md="8" className="product-details">
            {product.outOfStock ? (
              <div className="stock-info out-of-stock">Out of Stock</div>
            ) : (
              <div className="stock-info in-stock">In Stock</div>
            )}
            <h3 className="product-name">{product.name}</h3>
            <div className="product-price">${product.price}</div>
            <p className="product-description">{product.description}</p>
            <div className="product-actions">
              <div className="product-quantity">
                <div className="action minus" onClick={decreaseProductQuantity}>
                  <i className="fa fa-minus" />
                </div>
                <input
                  type="number"
                  min={1}
                  max={10}
                  step={1}
                  value={productQuantity}
                  onChange={(e) => setProductQuantity(e.target.value)}
                  disabled
                />
                <div className="action plus" onClick={increaseProductQuantity}>
                  <i className="fa fa-plus" />
                </div>
              </div>
              <div className="actionButtons">
                <Button
                  className="addToCart"
                  variant="addtocart"
                  size="big"
                  disabled={
                    product.outOfStock || submitting || wishlistSubmitting
                  }
                  onClick={addToCartButton}
                >
                  <span>Add to Cart</span>
                  {submitting && <Spinner as="span" animation="grow" />}
                </Button>
                <Button
                  className={`addToWishlist ${
                    addedToWishlist ? "addedToWishlist" : null
                  }`}
                  variant="addtowishlist"
                  size="big"
                  onClick={addToWishlist}
                  disabled={
                    product.outOfStock ||
                    submitting ||
                    wishlistSubmitting ||
                    addedToWishlist
                  }
                >
                  <span>
                    {addedToWishlist ? "Added to Wishlist" : "Add to Wishlist"}
                  </span>
                  {wishlistSubmitting && (
                    <Spinner
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="grow"
                    />
                  )}
                </Button>
              </div>
            </div>
            {showLoginMsg ? (
              <Alert
                variant="danger"
                className="text-center mt-3 mb-0"
                onClose={() => setShowLoginMsg(false)}
                dismissible
              >
                You must be logged in. Please{" "}
                <Button variant="success" onClick={headToLogin}>
                  Login
                </Button>
              </Alert>
            ) : (
              ""
            )}
          </Col>
          {/* <div className="product-container"></div> */}
        </Row>
        {startSimilarFetch && (
          <>
            <Heading title="Similar Products" />
            {similarFetching ? (
              <PageCenter>
                <PageSpinner />
              </PageCenter>
            ) : (
              <Container fluid>
                {similarProducts &&
                Array.isArray(similarProducts) &&
                similarProducts.length > 0 ? (
                  <ReactOwlCarousel
                    className="owl-theme brand"
                    id="slider2"
                    items={5}
                    loop
                    dots
                    nav={false}
                    margin={20}
                    autoplay
                    autoplayTimeout={5000}
                    autoplayHoverPause
                    responsiveClass
                    responsive={{
                      0: {
                        items: 1,
                      },
                      500: {
                        items: 2,
                      },
                      730: {
                        items: 3,
                      },
                      950: {
                        items: 4,
                      },
                      1170: {
                        items: 5,
                      },
                      1375: {
                        items: 6,
                      },
                    }}
                  >
                    {similarProducts.map((item) => {
                      return (
                        <div
                          key={item._id}
                          className="card product-card"
                          onClick={() => history.push(`/products/${item.slug}`)}
                        >
                          <LazyLoad height={200}>
                            <div className="product-image">
                              <img
                                className="card-img-top"
                                loading="lazy"
                                src={productUrl(item.banner)}
                                alt={item.name}
                              />
                            </div>
                          </LazyLoad>
                          <div className="card-body">
                            <h6 className="card-title">
                              <div className="w-100 text-center font-weight-bold">
                                ${item.price}
                              </div>
                              <div className="w-100" style={{ height: "65px" }}>
                                {transName(item)}
                              </div>
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </ReactOwlCarousel>
                ) : null}
              </Container>
            )}
          </>
        )}
      </Container>
      <Modal
        size="lg"
        show={showSuccessModal}
        onHide={closeProductSuccessModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>This item has been added to the cart.</h5>
          <button
            className="btn btn-outline-secondary btn-circle"
            onClick={closeProductSuccessModal}
          >
            <i className="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <img
                  src={productUrl(product.banner)}
                  className="img-fluid imgSize"
                  alt="cat-product"
                />
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12">
                <div>
                  <span className="font-weight-bold">Product: </span>
                  {transName(product)}
                </div>
                <div>
                  <span className="font-weight-bold">Quantity: </span>
                  {addedQuantity}
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4 mb-3 d-flex justify-content-between flex-wrap">
            <Button
              variant="info"
              className="rounded-pill"
              onClick={closeProductSuccessModal}
            >
              {trans.continueShopping}
            </Button>
            <Button
              variant="success"
              className="rounded-pill"
              onClick={() => {
                history.push("/cart");
              }}
            >
              {trans.proceedToCart}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default ProductPage;
