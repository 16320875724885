import React from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import PaymentMethodCard from "../CustomComponents/PaymentMethodCard";
import ShopByHeading from "../CustomComponents/ShopByHeading";
import OrderSummeryModule from "./OrderSummeryMoule";
import OurService from "./OurService";

const AliPay = () => {
  return (
    <>
      <BreadcrumbComponent from="Home" to="Trolly" />
      <ShopByHeading title="SELECT PAYMENT METHOD" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 col-md-9 col-12  d-flex justify-content-center align-items-center flex-column">
            <PaymentMethodCard />
            Ali Pay
          </div>
          <OrderSummeryModule />
        </div>
      </div>
      <OurService />
    </>
  );
};
export default AliPay;
