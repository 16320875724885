import en from "./en";
import hk from "./hk";
export const languageList = {
  en,
  hk,
};

export const languageOptions = [
  { id: "hk", text: "繁體", image: "hk.png" },
  { id: "en", text: "EN ", image: "us.png" },
];
